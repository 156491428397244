#reservation,
#reservationhall {

    label {
        margin-bottom: 0;
        font-size: 12px;
        text-transform: uppercase;

        & > span {
            font-size: 12px;
            margin-left: 50px;
        }
    }

    .btn {
        width: 140px;
        /*height: 50px;*/
        background-color: #cc0001;
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        margin-left: 5px;
        margin-right: 5px;

        @media screen and (max-width: 1023px) {
            margin: auto;
            height: 50px;
        }
    }

    .form-group {
        /*margin-right: 35px;*/
        padding: 0.5rem;
        margin-bottom: 0;
        border: 1px solid #d8d8d8;
        width:100%;
        @media screen and (max-width: 1023px) {
            margin-right: 0;
            border: none;
        }
    }

    .form-group_daterange {
        position: relative;
        /*padding-left: 0;*/
    }


    .form-control_daterange,
    .form-control_text {
        border: 0;
        border-bottom: 1px solid #828282;
        border-radius: 0;
        padding-left: 0;


    }

    .form-control_daterange {
        padding-right: 30px;
        background: url(/img/icons/form-arrow.png) center right no-repeat;

    }

    textarea.form-control {
        border: 1px solid #828282;
        height: 195px;
        margin-top: 3px;
    }


    .jq-selectbox.jqselect.form-control {
        padding: 0;
        border: 0;
    }

    .jq-selectbox__select-text {
        line-height: 30px;
    }

    .jq-selectbox__select {
        height: 34px;
        padding: 0 45px 0 0;
        border: 0;
        border-bottom: 1px solid #828282;
        border-radius: 0;
        background: #fff;
        box-shadow: none;

        color: #000;
        font-size: 14px;
        text-shadow: none;
    }

    .jq-selectbox__trigger {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 34px;
        height: 34px;
        border-left: 0;
        background-color: transparent;
        border-radius: 2px;
    }

    .jq-selectbox__trigger-arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 0;
        border-top: 0px solid #fff;
        border-right: 0px solid transparent;
        border-left: 0px solid transparent;
        font-size: 34px;
        color: #b30b0a;
        display: inline-block;
        width: 25px;
        line-height: 30px;

        &:before {
            content: '\e80d';
            font-family: "fontello";
            width: 25px;
            text-align: center;
            line-height: 1em;
        }

    }

    .jq-selectbox:hover .jq-selectbox__trigger-arrow {
        border-top-color: #fff;
    }

    .jq-selectbox li.selected {
        background-color: #e2e8eb; //
        color: #000;
    }

    .jq-selectbox li {
        min-height: 18px;
        padding: 5px 10px 6px;
        color: #000;
        font-size: 14px;

        &:hover {
            background-color: #e2e8eb;
            color: #000;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #e2e8eb;
        }

    }

    .guests {
        position: relative;
        height: 30px;
        padding-bottom: 0;
        border-bottom: 1px solid #828282;
        display: flex;
        align-items: center;

        &:before {
            content: '\e80d';
            position: absolute;
            top: -4;
            right: 0;
            height: 30px;
            font-size: 34px;
            color: #b30b0a;
            font-family: "fontello";
            width: 25px;
            text-align: center;
            line-height: 1em;
        }

        &:hover {
            .guests__list {
                opacity: 1;
            }
        }

        &__list {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #707070;
            border-radius: 4px;
            @media screen and (max-width: 1024px) {
                left: -20px;
                right: -20px;

            }
            @media screen and (max-width: 1023px) {
                left: 0;
                right: auto;

            }

        }

        &__item {
            display: flex;
            padding: 15px 5px;

            &:last-child {
                padding: .5rem;
            }

            input {
                width: 30px;
                margin-left: 10px
            }
        }

    }

}


#block-reservationhall {
    padding: 0px;
    //background-color: #fff;//
    display: none;
    z-index: 9999;
    position: absolute;
    left: 0;
    right: 0;
    top: 800px;
    height: 300px;
}


#open-search {
    display: block;

    /*

    background: url(/img/icons/form-arrow.png) center right no-repeat;
    color: #000;

    &:hover {
        color: #000;
    }

    */
    background: #cc0001;
    color: #fff;
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
    border: 1px solid #fff;
    border-radius: 4px; //

}


#reservationhall {

    width: 1100px;
    margin: auto;
    border: 1px solid #707070;
    border-top: none;
    background-color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; //
    padding: 20px;


    @media screen and (max-width: 1450px) {
        max-width: 1000px;
    }

    @media screen and (max-width: 1023px) {
        max-width: 100%;
        border: 0;
        padding: 0;
    }


    h5 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    p {

        font-size: 14px;
        margin-bottom: 0;
    }

    .btn {
        width: 100%;
        height: 50px;
        background-color: #cc0001;
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;

        @media screen and (max-width: 1023px) {
            margin: auto;
            width: 300px;
        }
    }


    .form-group {
        margin: 0;

    }
}

.reservation {
    &__list {
        display: flex;
        /*align-items: flex-end;*/
        /*justify-content: space-between;*/
        flex-wrap: wrap;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: stretch;
            padding: 15px;
            border: 1px solid #c4c4c4;

        }
        @media screen and (min-width: 1023px) {
            max-width: 700px;
            margin: auto;
        }

    }

    &__item {
        display: flex;
        /*flex-direction: column;*/

        &:not(:last-child) {
            //flex-basis: calc((100% - 150px) / 3);
            flex-basis: calc((100% - 150px) / 2);
        }


        @media screen and (max-width: 1023px) {
            margin-bottom: 40px;

        }

    }

}

.reservationhall {
    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: stretch;
            padding: 15px;
            border: 1px solid #c4c4c4;

        }

    }

    &__item {
        display: flex;
        flex-direction: column;

        flex-basis: calc(100% / 3);

        &:nth-child(2) {
            padding: 0 30px;

            @media screen and (max-width: 1023px) {
                padding: 0;

            }
        }


        @media screen and (max-width: 1023px) {
            margin-bottom: 40px;

        }

    }

}

.frame-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 130px;
    @media screen and (max-width: 1023px) {
        top: 350px;
    }
}

