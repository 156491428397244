.button-block {

  margin: 95px 0;
  @media screen and (max-width: 1024px) {
    margin: 70px 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
&__item  {
  margin: 0 10px 20px;
  flex-grow: 1;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px solid #dddddd;
    border-radius: 4px;
    //max-width: 200px;
    height: 40px;
    padding: 0 20px;
    font-size: 12px;
line-height: 1.2;
    text-transform: uppercase;

    &:hover {
      border-color: #cc0001;
      color: #cc0001;

    }

  }
}

}