.nav-tabs {

    flex-wrap: nowrap;
    margin-bottom: 90px;
    border-bottom: 0px solid #dee2e6;


    .nav-link {
        display: flex;
        align-items: center;
        font-size: 12px;
        border-radius: 0;

        &:focus,
        &:hover {
            border: 1px solid transparent;
            color: #000;
        }

    }

    .nav-item.show .nav-link,
    .nav-link.active {
        color: #fff;
        background-color: #a19379;
        border-color: #a19379;
        cursor: text;

        &:focus,
        &:hover {
            color: #fff;
        }


    }

    .nav-item {
        margin: 0 30px 0 0;
        border: 1px solid #a19379;
        border-radius: 4px;

    }


}

.tab-content {

    h2 {
        font-size: 26px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #d0c8bb;
    }

    p {
        font-size: 18px;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 15px 0;
    }

    img {
        max-width: 60%;
        margin-bottom: 30px;

        @media screen and (max-width: 667px) {
            max-width: 100%;
        }

    }

}
