#header {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    background: #eac17a url(/img/bg/main.jpg) no-repeat center center;
    background-size: cover;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)); //
    }

    .cruise {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8%;
        background-color: #cc0001;
        color: #fff;
        text-align: center;
        font-size: 18px;
        padding: 10px 0;
        max-width: 320px;
        border: 1px solid #fff;
        border-radius: 4px;
        margin: auto;
    }
}

.header {
    &__logo {
        position: absolute;
        top: 60px;
        left: 60px;
        width: 76px;
        height: 121px;
        /*background: url("/img/logo.png");*/
        z-index: 50;

        @media screen and (max-width: 1430px) {
            left: 50%;
            margin-left: -16px;
        }
    }

    &__logo-raddison {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 250px;
        height: 70px;
        margin-left: -125px;
        margin-top: -35px;
        /*background: url("/img/logo-radisson.png");*/

    }

    &__social {
        position: absolute;
        top: 50%;
        //right: 60px;//
        right: 4%;
        height: 114px;
        margin-top: -57px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1470px) {
            right: 15px;
        }
        @media screen and (max-width: 667px) {
            flex-direction: row;
            display: none;
        }

        & > a {
            @media screen and (max-width: 667px) {
                margin-right: 15px;
            }
        }

    }

    & .menu {
        position: absolute;
        top: 59px;
        left: 0;
        right: 0;
        max-width: 1100px;
        margin: auto;

        @media screen and (max-width: 1450px) {
            max-width: 1000px;
        }

        @media screen and (max-width: 1430px) {
            display: none;
        }

        & > ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style-type: none;

            & > li {

                &:not(:last-child) {
                    margin-right: 1%;

                    @media (max-width: 1060px) {
                        //margin-right: 3%;
                    }

                }

                & > a {
                    font-size: 14px;
                    text-transform: uppercase;
                    text-decoration: none; //
                    color: #fff;
                    @media screen and (min-width: 1024px) and (max-width: 1450px) {
                        font-size: 12px;
                    }

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }

                &.active > a {
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }

    h1 {
        //font-size: 40px;
        font: 700 40px/1 'GothaProBol';
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 53%;
        left: 0;
        right: 0;
        text-align: center;
        //letter-spacing: 3px;
        letter-spacing: 6px;
        @media screen and (max-width: 420px) {
            font-size: 30px;
            top: 60%;
        }
    }

    &__searchBarForm {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8%;
        max-width: 700px;
        margin: auto;
        padding: 5px;
        border: 1px solid #707070;
        background-color: #fff;
        border-radius: 4px;

        @media screen and (max-width: 1450px) {
            max-width: 700px;
        }


        @media screen and (max-width: 1024px) {
            margin: 0 15px;
        }

        @media screen and (max-width: 1023px) {
            display: none;
        }


        &_number {
            max-width: 680px;
        }


        &_hall {
            height: 260px;
            bottom: 3%;

        }
    }

    .searchBarForm.cruise {
        @media screen and (min-width: 1023px) {
            display: none;
        }
    }

    &__search {
        //position: absolute;
        //top: 65px;
        //right: 10%;
        z-index: 999;
        width: 25px;
        height: 25px;
        background: url("/img/icons/search.png") center no-repeat;

        @media screen and (min-width: 1450px) {
            margin-right: 27px;
        }

        @media screen and (min-width: 1430px) {
            margin-right: 15px;
        }

        @media screen and (max-width: 1430px) {
            position: fixed;
            left: 70px;
            top: 15px;
        }
    }

    &__block {
        position: absolute;
        top: 60px;
        right: 4%;
        z-index: 999;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1470px) {
            right: 15px;
        }

    }

}
.reservations-title{
    font-weight: bold;
    text-transform: uppercase;
}
.slick-track {
    margin: auto 0;
}

