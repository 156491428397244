.description-object {
  padding-left: 6vw; //


  @media screen and (max-width: 992px) {
    padding: 40px 0 0 0; //
  }


  h2 {
    font-size: 30px;
    line-height: 1.1;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    margin: 25px 0 0;
  }

  .parameters {
    &__list {
      display: flex;
      margin-top: 25px;

      &_3d {
        flex-direction: column;
      }
    }

    &__item {
      position: relative;
      padding-left: 30px;
      font-size: 14px;

      &_area {
        &:before {
          content: ' ';
          position: absolute;
          top: 1px;
          left: 0;
          width: 16px;
          height: 16px;
          background: url("/img/icons/area.png");
        }
      }

      &_3d {
        color: #000;
        font-weight: bold;
        font-size: 1.6rem;

        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:before {
          content: ' ';
          position: absolute;
          top: 1px;
          left: 0;
          width: 17px;
          height: 20px;
          background: url("/img/icons/3d-tour.png");
        }
      }


      &_capacity {
        margin-left: 15px;
        padding-left: 50px;
        border-left: 1px solid #464646;

        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 15px;
          width: 19px;
          height: 20px;
          background: url("/img/icons/user.png");
        }
      }

    }

  }

  ul {
    list-style-type: none;
    padding: 40px 0 0 0;

    & > li {
      padding: 5px 0;
      font-weight: bold; //
    }
  }


  ul.balloon {
    padding: 0 0 0 15px;

    li {
      list-style-image: url('/img/circle.png');
      font-size: 14px;
      padding-left: 10px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .service-list {

    & > h4 {
      font-size: 16px;
      margin: 30px 0 20px 0;
    }

    ul {
      padding: 0 0 0 15px;

      li {
        list-style-image: url('/img/circle.png');
        font-size: 14px;
        padding-left: 10px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }


  & > .btn-collapse {

    .icon {
      color: #cc0001;
      font-size: 20px;
    }
  }


}