.service-list {
  & > h4 {
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 0 38px;
  }
  ul {
    padding: 0 0 0 15px;
    li {
      list-style-image: url('/img/circle.png');
      font-size: 14px;
      padding-left: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}