.youtube-video-wrapper {
  width: 720px;
  margin: auto;
position: relative;
  height: 480px;

  @media screen and (max-width: 768px) {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: auto;
    overflow: hidden;


  }
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

.header-video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  //position: absolute;
  //right: 0;
  //bottom: 0;
  //min-width: 100%;
  //min-height: 100%;
  //width: 100%;

/*  @media screen and (max-width: 425px) {
    height: 100%;
    //left: 0;
    //margin-left: -65%;
    //position: absolute;
    //width: auto;
    //top: 0;
    //right: 0;
    //bottom: 0;
    width: 100%;
    transform: scale(2.3);
  }*/
}
