%slick-dots {

  .slick-dots {
    bottom: -40px;
    left: 0;
    right: 0;
    width: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      display: block;

      &.slick-active button:before {
        font-size: 1px;
        color: #851e1f;
        background-color: #851e1f;
        border: 1px solid #851e1f;
        border-radius: 50%;
        opacity: 1;
      }

      & button {
        padding: 0 !important;
        width: 10px;
        height: 10px;
        &:hover,
        &:focus {
          &:before {
            opacity: 1;
            color: transparent;
            border: 1px solid #851e1f;
            border-radius: 50%;
            background-color: #851e1f;
          }
        }

        &:before {
          //font-size: 6px;
          line-height: 10px;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          opacity: 1;
          color: transparent;
          border: 1px solid #c3c3c3;
          background-color: #c3c3c3;

          border-radius: 50%;
        }

      }

    }
  }

  &.slick-dotted.slick-slider {
    margin-bottom: 55px !important;
  }
}

%slick-arrow {

  & .arrow-prev,
  & .arrow-next {
    position: absolute;
    z-index: 100;
    margin: 0;
    top: 32%;
  }

  & .arrow-prev {
    left: 0;
    @media screen and (min-width: 1680px) {
      left: -60px;
    }
  }
  & .arrow-next {
    right: 0;
    @media screen and (min-width: 1680px) {
      right: -60px;
    }
  }

  & .arrow-next.slick-arrow,
  & .arrow-prev.slick-arrow {
    color: #d9c79e;
    text-align: center;

    & > img {
      padding: 0;
      cursor: pointer;
    }

  }
}

%indention {
  margin: 95px 0 0;
  padding-bottom: 95px;
  @media screen and (max-width: 1024px) {
    margin: 70px 0 0;
    padding-bottom: 70px;

  }
}

%bg-color-darkgrey {
  background-color: #dddddd;
  @extend %indention;
}

/*
%bg-color-darkgrey {
  background-color: #dddddd;
  margin: 95px 0 0;
  padding-bottom: 95px;
  @media screen and (max-width: 1024px) {
    margin: 70px 0 0;
    padding-bottom: 70px;

  }
}*/