.slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}

.slider-btn {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 60px;
  @media screen and (min-width: 1680px) {
    padding: 0; //
  }
  @media screen and (max-width: 1025px) {
    padding: 0; //
  }

  .slick-list {
    @media screen and (max-width: 1025px) {
      padding: 0 30px 0 0;
    }

    @media screen and (max-width: 769px) {
      padding: 0 80px 0 0;
    }

    @media screen and (max-width: 667px) {
      padding: 0 40px 0 0;
    }


  }

  .slick-slide.slick-active {
    .slider-btn__link:hover {
      box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.2);
    }
  }

  &__link {
    background-color: #fff;
    display: block;
    text-align: center;
    padding: 0 0 40px;
    margin: 0 14px 11px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0;

    &:hover {
      text-decoration: none;

      .slider-btn__btn {
        background-color: #cc0001;
        color: #fff;
      }

      .slider-btn__img {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
      }
    }


    @media screen and (max-width: 1025px) {
      margin: 0 15px 11px 0;
    }
  }

  &__bg {

    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/carousel/btn/3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px 4px 0 0;
    font-size: 0;
  }


  &__img {
    position: relative;
    display: inline-block;
    width: 100%;

    h3 {
      position: absolute;
      left: 30px;
      right: 20%;
      top: 0;
      bottom: 0;
      font-size: 18px;
      line-height: 1.4;
      color: #fff;
      display: flex;
      align-items: center;
      text-align: left;
      text-transform: uppercase;
    }
  }

  &__btn {
    height: 40px;
    line-height: 40px;
    margin: 30px 30px 0;
    border: 1px solid #cc0001;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #cc0001;
    max-width: 250px;


    @media screen and (min-width: 420px) and (max-width: 667px) {
      margin: 30px auto; //
    }

  }

  &__text {
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 30px 30px 0;
  }

  @extend %slick-arrow;
  @extend %slick-dots;

}

