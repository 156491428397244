@charset "UTF-8";
/* ATTENTION! the scss compiler works. can not add styles to /css/main.css */
@font-face {
  font-family: "GothaProBla";
  src: url("/fonts/GothaProBla.eot");
  src: local("GothaProBla"), url("/fonts/GothaProBla.woff") format("woff"), url("/fonts/GothaProBla.ttf") format("truetype"), url("/fonts/GothaProBla.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothaProBlaIta";
  src: url("/fonts/GothaProBlaIta.eot");
  src: local("GothaProBlaIta"), url("/fonts/GothaProBlaIta.woff") format("woff"), url("/fonts/GothaProBlaIta.ttf") format("truetype"), url("/fonts/GothaProBlaIta.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GothaProBol";
  src: url("/fonts/GothaProBol.eot");
  src: local("GothaProBol"), url("/fonts/GothaProBol.woff") format("woff"), url("/fonts/GothaProBol.ttf") format("truetype"), url("/fonts/GothaProBol.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothaProBolIta";
  src: url("/fonts/GothaProBolIta.eot");
  src: local("GothaProBolIta"), url("/fonts/GothaProBolIta.woff") format("woff"), url("/fonts/GothaProBolIta.ttf") format("truetype"), url("/fonts/GothaProBolIta.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GothaProLig";
  src: url("/fonts/GothaProLig.eot");
  src: local("GothaProLig"), url("/fonts/GothaProLig.woff") format("woff"), url("/fonts/GothaProLig.ttf") format("truetype"), url("/fonts/GothaProLig.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothaProLigIta";
  src: url("/fonts/GothaProLigIta.eot");
  src: local("GothaProLigIta"), url("/fonts/GothaProLigIta.woff") format("woff"), url("/fonts/GothaProLigIta.ttf") format("truetype"), url("/fonts/GothaProLigIta.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GothaProMed";
  src: url("/fonts/GothaProMed.eot");
  src: local("GothaProMed"), url("/fonts/GothaProMed.woff") format("woff"), url("/fonts/GothaProMed.ttf") format("truetype"), url("/fonts/GothaProMed.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothaProMedIta";
  src: url("/fonts/GothaProMedIta.eot");
  src: local("GothaProMedIta"), url("/fonts/GothaProMedIta.woff") format("woff"), url("/fonts/GothaProMedIta.ttf") format("truetype"), url("/fonts/GothaProMedIta.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GothaProNarBol";
  src: url("/fonts/GothaProNarBol.eot");
  src: local("GothaProNarBol"), url("/fonts/GothaProNarBol.woff") format("woff"), url("/fonts/GothaProNarBol.ttf") format("truetype"), url("/fonts/GothaProNarBol.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothaProNarMed";
  src: url("/fonts/GothaProNarMed.eot");
  src: local("GothaProNarMed"), url("/fonts/GothaProNarMed.woff") format("woff"), url("/fonts/GothaProNarMed.ttf") format("truetype"), url("/fonts/GothaProNarMed.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothaProReg";
  src: url("/fonts/GothaProReg.eot");
  src: local("GothaProReg"), url("/fonts/GothaProReg.woff") format("woff"), url("/fonts/GothaProReg.ttf") format("truetype"), url("/fonts/GothaProReg.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "fontello";
  src: url("../../fonts/fontello.eot");
  src: url("../../fonts/fontello.eot#iefix") format("embedded-opentype"), url("../../fonts/fontello.woff2") format("woff2"), url("../../fonts/fontello.woff") format("woff"), url("../../fonts/fontello.ttf") format("truetype"), url("../../fonts/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/**
  * position: before | after | both
 */
.icon,
.fa {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
.icon span,
.fa span {
  font-family: "HelveticaNeueCyr", Helvetica, Arial, sans-serif;
}

/*
<i class="fa fa-chevron-right"></i>
background-color: #cbbe96;
color: #000;
*/
.icon_lg {
  font-size: 4em;
}

.icon_md {
  font-size: 3em;
}

.icon_xsm {
  font-size: 2.5em;
}

.icon_sm {
  font-size: 2em;
}

.icon_xs {
  font-size: 1.3em;
  line-height: 0.75em;
}

.icon_blog {
  font-size: 0.9em;
}

.icon_xxs {
  font-size: 1em;
}

/*
.icon-vkontakte{ @include fontello('\f189');}
*/
.icon-location-outline:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-location-outline:before {
  content: "\e800";
}

/* '' */
.icon-search:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-search:before {
  content: "\e801";
}

/* '' */
.icon-phone:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-phone:before {
  content: "\e802";
}

/* '' */
.icon-play:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-play:before {
  content: "\e803";
}

/* '' */
.icon-down-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-down-open:before {
  content: "\e804";
}

/* '' */
.icon-up-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-up-open:before {
  content: "\e805";
}

/* '' */
.icon-left-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-left-open:before {
  content: "\e806";
}

/* '' */
.icon-right-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-right-open:before {
  content: "\e807";
}

/* '' */
.icon-left-dir:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-left-dir:before {
  content: "\e808";
}

/* '' */
.icon-up-dir:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-up-dir:before {
  content: "\e809";
}

/* '' */
.icon-play-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-play-1:before {
  content: "\e80a";
}

/* '' */
.icon-down-dir-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-down-dir-1:before {
  content: "\e80b";
}

/* '' */
.icon-right-dir-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-right-dir-1:before {
  content: "\e80c";
}

/* '' */
.icon-down-open-mini:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-down-open-mini:before {
  content: "\e80d";
}

/* '' */
.icon-user-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-user-1:before {
  content: "\e80e";
}

/* '' */
.icon-volume-up:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-volume-up:before {
  content: "\e80f";
}

/* '' */
.icon-location:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-location:before {
  content: "\e810";
}

/* '' */
.icon-clipboard:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-clipboard:before {
  content: "\e811";
}

/* '' */
.icon-rss-alt:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-rss-alt:before {
  content: "\e812";
}

/* '' */
.icon-search-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-search-1:before {
  content: "\e813";
}

/* '' */
.icon-align-left:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-align-left:before {
  content: "\e814";
}

/* '' */
.icon-left-open-mini:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-left-open-mini:before {
  content: "\e815";
}

/* '' */
.icon-right-open-mini:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-right-open-mini:before {
  content: "\e816";
}

/* '' */
.icon-up-open-mini:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-up-open-mini:before {
  content: "\e817";
}

/* '' */
.icon-down-open-big:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-down-open-big:before {
  content: "\e818";
}

/* '' */
.icon-left-open-big:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-left-open-big:before {
  content: "\e819";
}

/* '' */
.icon-right-open-big:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-right-open-big:before {
  content: "\e81a";
}

/* '' */
.icon-up-open-big:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-up-open-big:before {
  content: "\e81b";
}

/* '' */
.icon-star:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-star:before {
  content: "\e81c";
}

/* '' */
.icon-mail:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-mail:before {
  content: "\e81d";
}

/* '' */
.icon-mail-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-mail-1:before {
  content: "\e81e";
}

/* '' */
.icon-mail-2:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-mail-2:before {
  content: "\e81f";
}

/* '' */
.icon-clock:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-clock:before {
  content: "\e820";
}

/* '' */
.icon-mail-3:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-mail-3:before {
  content: "\e821";
}

/* '' */
.icon-clock-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-clock-1:before {
  content: "\e822";
}

/* '' */
.icon-twitter-circled:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-twitter-circled:before {
  content: "\e823";
}

/* '' */
.icon-calendar:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-calendar:before {
  content: "\e824";
}

/* '' */
.icon-twitter:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-twitter:before {
  content: "\f099";
}

/* '' */
.icon-facebook:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-facebook:before {
  content: "\f09a";
}

/* '' */
.icon-youtube-play:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-youtube-play:before {
  content: "\f16a";
}

/* '' */
.icon-instagram:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-instagram:before {
  content: "\f16d";
}

/* '' */
.icon-vkontakte:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-vkontakte:before {
  content: "\f189";
}

/* '' */
.icon-odnoklassniki:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-odnoklassniki:before {
  content: "\f263";
}

/* '' */
.icon-twitter-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-twitter-1:before {
  content: "\f309";
}

/* '' */
.icon-vkontakte-rect:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-vkontakte-rect:before {
  content: "\f30a";
}

/* '' */
.icon-gplus:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-gplus:before {
  content: "\f30f";
}

/* '' */
.icon-gplus-circled:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-gplus-circled:before {
  content: "\f310";
}

/* '' */
.icon-instagram-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-instagram-1:before {
  content: "\f32d";
}

/* '' */
.icon-list:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-list:before {
  content: "\e825";
}

.icon-th:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-th:before {
  content: "\e826";
}

.icon-list-1:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-list-1:before {
  content: "\e827";
}

.berrosha-down-dir:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-down-dir:before {
  content: "\e800";
}

/* '' */
.berrosha-up-dir:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-up-dir:before {
  content: "\e801";
}

/* '' */
.berrosha-left-dir:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-left-dir:before {
  content: "\e802";
}

/* '' */
.berrosha-right-dir:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-right-dir:before {
  content: "\e803";
}

/* '' */
.berrosha-down-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-down-open:before {
  content: "\e804";
}

/* '' */
.berrosha-left-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-left-open:before {
  content: "\e805";
}

/* '' */
.berrosha-right-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-right-open:before {
  content: "\e806";
}

/* '' */
.berrosha-up-open:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-up-open:before {
  content: "\e807";
}

/* '' */
.berrosha-mail:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-mail:before {
  content: "\e808";
}

/* '' */
.berrosha-clock:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-clock:before {
  content: "\e809";
}

/* '' */
.berrosha-phone {
  transform: rotate(30deg);
}
.berrosha-phone:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-phone:before {
  content: "\e80a";
}

/* '' */
.berrosha-location:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-location:before {
  content: "\f031";
}

/* '' */
.berrosha-facebook:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-facebook:before {
  content: "\f09a";
}

/* '' */
.berrosha-mail-alt:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-mail-alt:before {
  content: "\f0e0";
}

/* '' */
.berrosha-angle-left:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-angle-left:before {
  content: "\f104";
}

/* '' */
.berrosha-angle-right:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-angle-right:before {
  content: "\f105";
}

/* '' */
.berrosha-angle-up:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-angle-up:before {
  content: "\f106";
}

/* '' */
.berrosha-angle-down:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-angle-down:before {
  content: "\f107";
}

/* '' */
.berrosha-tumblr:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-tumblr:before {
  content: "\f173";
}

/* '' */
.berrosha-vkontakte:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-vkontakte:before {
  content: "\f189";
}

/* '' */
.berrosha-odnoklassniki:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-odnoklassniki:before {
  content: "\f263";
}

/* '' */
.berrosha-instagram:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.berrosha-instagram:before {
  content: "\f32d";
}

/* '' */
.thumbnail-slider-inner-nav .slick-dots, .thumbnail-slider-inner-for .slick-dots, .thumbnail-slider-nav .slick-dots,
.thumbnail-slider-offer-nav .slick-dots, .thumbnail-slider-for .slick-dots,
.thumbnail-slider-offer-for .slick-dots, .slider-btn .slick-dots {
  bottom: -40px;
  left: 0;
  right: 0;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.thumbnail-slider-inner-nav .slick-dots li, .thumbnail-slider-inner-for .slick-dots li, .thumbnail-slider-nav .slick-dots li,
.thumbnail-slider-offer-nav .slick-dots li, .thumbnail-slider-for .slick-dots li,
.thumbnail-slider-offer-for .slick-dots li, .slider-btn .slick-dots li {
  display: block;
}
.thumbnail-slider-inner-nav .slick-dots li.slick-active button:before, .thumbnail-slider-inner-for .slick-dots li.slick-active button:before, .thumbnail-slider-nav .slick-dots li.slick-active button:before,
.thumbnail-slider-offer-nav .slick-dots li.slick-active button:before, .thumbnail-slider-for .slick-dots li.slick-active button:before,
.thumbnail-slider-offer-for .slick-dots li.slick-active button:before, .slider-btn .slick-dots li.slick-active button:before {
  font-size: 1px;
  color: #851e1f;
  background-color: #851e1f;
  border: 1px solid #851e1f;
  border-radius: 50%;
  opacity: 1;
}
.thumbnail-slider-inner-nav .slick-dots li button, .thumbnail-slider-inner-for .slick-dots li button, .thumbnail-slider-nav .slick-dots li button,
.thumbnail-slider-offer-nav .slick-dots li button, .thumbnail-slider-for .slick-dots li button,
.thumbnail-slider-offer-for .slick-dots li button, .slider-btn .slick-dots li button {
  padding: 0 !important;
  width: 10px;
  height: 10px;
}
.thumbnail-slider-inner-nav .slick-dots li button:hover:before, .thumbnail-slider-inner-for .slick-dots li button:hover:before, .thumbnail-slider-nav .slick-dots li button:hover:before,
.thumbnail-slider-offer-nav .slick-dots li button:hover:before, .thumbnail-slider-for .slick-dots li button:hover:before,
.thumbnail-slider-offer-for .slick-dots li button:hover:before, .slider-btn .slick-dots li button:hover:before, .thumbnail-slider-inner-nav .slick-dots li button:focus:before, .thumbnail-slider-inner-for .slick-dots li button:focus:before, .thumbnail-slider-nav .slick-dots li button:focus:before,
.thumbnail-slider-offer-nav .slick-dots li button:focus:before, .thumbnail-slider-for .slick-dots li button:focus:before,
.thumbnail-slider-offer-for .slick-dots li button:focus:before, .slider-btn .slick-dots li button:focus:before {
  opacity: 1;
  color: transparent;
  border: 1px solid #851e1f;
  border-radius: 50%;
  background-color: #851e1f;
}
.thumbnail-slider-inner-nav .slick-dots li button:before, .thumbnail-slider-inner-for .slick-dots li button:before, .thumbnail-slider-nav .slick-dots li button:before,
.thumbnail-slider-offer-nav .slick-dots li button:before, .thumbnail-slider-for .slick-dots li button:before,
.thumbnail-slider-offer-for .slick-dots li button:before, .slider-btn .slick-dots li button:before {
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  opacity: 1;
  color: transparent;
  border: 1px solid #c3c3c3;
  background-color: #c3c3c3;
  border-radius: 50%;
}
.slick-dotted.slick-slider.thumbnail-slider-inner-nav, .slick-dotted.slick-slider.thumbnail-slider-inner-for, .slick-dotted.slick-slider.thumbnail-slider-nav,
.slick-dotted.slick-slider.thumbnail-slider-offer-nav, .slick-dotted.slick-slider.thumbnail-slider-for,
.slick-dotted.slick-slider.thumbnail-slider-offer-for, .slick-dotted.slick-slider.slider-btn {
  margin-bottom: 55px !important;
}

.thumbnail-slider-inner-nav .arrow-prev, .thumbnail-slider-nav .arrow-prev,
.thumbnail-slider-offer-nav .arrow-prev, .slider-btn .arrow-prev, .thumbnail-slider-inner-nav .arrow-next, .thumbnail-slider-nav .arrow-next,
.thumbnail-slider-offer-nav .arrow-next, .slider-btn .arrow-next {
  position: absolute;
  z-index: 100;
  margin: 0;
  top: 32%;
}
.thumbnail-slider-inner-nav .arrow-prev, .thumbnail-slider-nav .arrow-prev,
.thumbnail-slider-offer-nav .arrow-prev, .slider-btn .arrow-prev {
  left: 0;
}
@media screen and (min-width: 1680px) {
  .thumbnail-slider-inner-nav .arrow-prev, .thumbnail-slider-nav .arrow-prev,
  .thumbnail-slider-offer-nav .arrow-prev, .slider-btn .arrow-prev {
    left: -60px;
  }
}
.thumbnail-slider-inner-nav .arrow-next, .thumbnail-slider-nav .arrow-next,
.thumbnail-slider-offer-nav .arrow-next, .slider-btn .arrow-next {
  right: 0;
}
@media screen and (min-width: 1680px) {
  .thumbnail-slider-inner-nav .arrow-next, .thumbnail-slider-nav .arrow-next,
  .thumbnail-slider-offer-nav .arrow-next, .slider-btn .arrow-next {
    right: -60px;
  }
}
.thumbnail-slider-inner-nav .arrow-next.slick-arrow, .thumbnail-slider-nav .arrow-next.slick-arrow,
.thumbnail-slider-offer-nav .arrow-next.slick-arrow, .slider-btn .arrow-next.slick-arrow, .thumbnail-slider-inner-nav .arrow-prev.slick-arrow, .thumbnail-slider-nav .arrow-prev.slick-arrow,
.thumbnail-slider-offer-nav .arrow-prev.slick-arrow, .slider-btn .arrow-prev.slick-arrow {
  color: #d9c79e;
  text-align: center;
}
.thumbnail-slider-inner-nav .arrow-next.slick-arrow > img, .thumbnail-slider-nav .arrow-next.slick-arrow > img,
.thumbnail-slider-offer-nav .arrow-next.slick-arrow > img, .slider-btn .arrow-next.slick-arrow > img, .thumbnail-slider-inner-nav .arrow-prev.slick-arrow > img, .thumbnail-slider-nav .arrow-prev.slick-arrow > img,
.thumbnail-slider-offer-nav .arrow-prev.slick-arrow > img, .slider-btn .arrow-prev.slick-arrow > img {
  padding: 0;
  cursor: pointer;
}

.background-fill-light, .background-fill-dark, .events,
.service {
  margin: 95px 0 0;
  padding-bottom: 95px;
}
@media screen and (max-width: 1024px) {
  .background-fill-light, .background-fill-dark, .events,
  .service {
    margin: 70px 0 0;
    padding-bottom: 70px;
  }
}

.background-fill-light, .background-fill-dark, .events,
.service {
  background-color: #dddddd;
}

/*
%bg-color-darkgrey {
  background-color: #dddddd;
  margin: 95px 0 0;
  padding-bottom: 95px;
  @media screen and (max-width: 1024px) {
    margin: 70px 0 0;
    padding-bottom: 70px;

  }
}*/
* {
  font: 400 14px/1.4 "GothaProReg";
  color: #000;
  transition: font-size 0.4s ease;
}

:focus {
  outline: -webkit-focus-ring-color auto 0;
  outline: 0;
  box-shadow: 0;
}

.form-control:focus {
  background-color: #fff;
  border-color: inherit;
  outline: 0;
  box-shadow: none;
}

a {
  color: #000;
}
a:hover {
  text-decoration: none;
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 760px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1560px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container_padding {
    padding: 0 30px;
  }
}
@media screen and (min-width: 667px) and (max-width: 768px) {
  .container_padding {
    padding: 0 70px;
  }
}
@media (min-width: 1200px) {
  .container_inner {
    max-width: 1100px;
  }
}
.container_about {
  margin: 95px auto 0;
  padding-bottom: 95px;
}
@media screen and (max-width: 1024px) {
  .container_about {
    margin: 70px auto 0;
    padding-bottom: 70px;
  }
}

/*

font-size: 18px;

  @media screen and (min-width: 768px ) and (max-width: 1024px ){
    font-size: 16px;
  }
  @media screen and (max-width: 768px ){
    font-size: 14px;
  }

*/
.title-page {
  text-align: center;
  margin: 95px auto 40px;
}
@media screen and (max-width: 1024px) {
  .title-page {
    margin: 70px auto 40px;
  }
}
.title-page h2 {
  font: 700 34px "GothaProBol";
  text-transform: uppercase;
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .title-page h2 {
    font-size: 24px;
  }
}
.title-page p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .title-page p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.title-page .description {
  width: 60%;
  margin: 40px auto 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .title-page .description {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .title-page .description {
    width: 100%;
  }
}
.title-page .description p {
  font-size: 18px;
  text-align: left;
}

.card {
  border: 0;
}
.card-header, .card-body, .card-footer {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: white;
}

.btn-place {
  margin-top: 75px;
  width: 215px;
  padding: 12px;
  border: 2px solid #fff;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}
.btn-place:hover {
  text-decoration: none;
  color: #fff;
}
.btn-tours-tour {
  color: white;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  border: 2px solid #fff;
  padding: 12px;
}
@media screen and (min-width: 768px) {
  .btn-tours-tour {
    width: auto;
  }
}

.show-more {
  margin: 10px auto 0;
}
.show-more > a {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #b30b0a;
  display: flex;
  align-items: center;
}
.show-more > a > .icon {
  font-size: 34px;
  color: #b30b0a;
}
.show-more > a:hover {
  color: #544751;
  text-decoration: underline;
}
.show-more > a:hover .icon {
  color: #544751;
  text-decoration: none;
}

.background-fill-light {
  background-color: #ebebeb;
}

.congress-park {
  background-color: #ebebeb;
  margin: 0 0 70px;
  padding-bottom: 95px;
}
@media screen and (max-width: 1024px) {
  .congress-park {
    margin: 0 0 50px;
    padding-bottom: 70px;
  }
}

.rest {
  padding-bottom: 95px;
}
@media screen and (max-width: 1024px) {
  .rest {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 569px) {
  .rest .row {
    margin: 0;
  }
  .rest .slide-rest {
    margin-left: -15px;
    margin-right: -15px;
  }
  .rest .slick-dots li {
    margin: 0;
    width: 15px;
  }
}
@media screen and (max-width: 420px) {
  .rest .slick-dots li {
    margin: 0 2.5px;
    width: 10px;
  }
}

@media screen and (max-width: 569px) {
  .events .slick-dots li {
    margin: 0;
    width: 15px;
  }
}
@media screen and (max-width: 420px) {
  .events .slick-dots li {
    margin: 0 2.5px;
    width: 10px;
  }
}

.padding {
  padding-top: 95px;
}
@media screen and (max-width: 1024px) {
  .padding {
    padding-top: 70px;
  }
}

.col_line {
  position: relative;
}
.col_line:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 2px;
  background-color: #b5b5b5;
}

#modal-fullscreen .modal-dialog {
  width: 100%;
  min-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#modal-fullscreen .modal-content {
  height: auto;
  min-height: 100%;
  border: 0 none;
  border-radius: 0;
}
#modal-fullscreen .modal-title {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.5;
  margin-left: 15px;
  text-transform: uppercase;
}
#modal-fullscreen .modal-header {
  padding: 15px 15px 0 15px;
  align-items: center;
}
#modal-fullscreen .modal-header .close {
  padding: 0;
  margin: 0;
}
#modal-fullscreen .modal-header .close span {
  font-size: 30px;
  color: red;
}

#topbar {
  display: none;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 64px;
  text-align: center;
  background: #928a7d;
  z-index: 999;
}
@media screen and (min-width: 1024px) {
  #topbar {
    opacity: 0;
    height: 0;
    background: transparent;
  }
}
#topbar a {
  display: block;
  margin: 12px auto;
}

#header {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  background: #eac17a url(/img/bg/main.jpg) no-repeat center center;
  background-size: cover;
}
#header:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
#header .cruise {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8%;
  background-color: #cc0001;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  max-width: 320px;
  border: 1px solid #fff;
  border-radius: 4px;
  margin: auto;
}

.header__logo {
  position: absolute;
  top: 60px;
  left: 60px;
  width: 76px;
  height: 121px;
  /*background: url("/img/logo.png");*/
  z-index: 50;
}
@media screen and (max-width: 1430px) {
  .header__logo {
    left: 50%;
    margin-left: -16px;
  }
}
.header__logo-raddison {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 250px;
  height: 70px;
  margin-left: -125px;
  margin-top: -35px;
  /*background: url("/img/logo-radisson.png");*/
}
.header__social {
  position: absolute;
  top: 50%;
  right: 4%;
  height: 114px;
  margin-top: -57px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1470px) {
  .header__social {
    right: 15px;
  }
}
@media screen and (max-width: 667px) {
  .header__social {
    flex-direction: row;
    display: none;
  }
}
@media screen and (max-width: 667px) {
  .header__social > a {
    margin-right: 15px;
  }
}
.header .menu {
  position: absolute;
  top: 59px;
  left: 0;
  right: 0;
  max-width: 1100px;
  margin: auto;
}
@media screen and (max-width: 1450px) {
  .header .menu {
    max-width: 1000px;
  }
}
@media screen and (max-width: 1430px) {
  .header .menu {
    display: none;
  }
}
.header .menu > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
}
.header .menu > ul > li:not(:last-child) {
  margin-right: 1%;
}
.header .menu > ul > li > a {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}
@media screen and (min-width: 1024px) and (max-width: 1450px) {
  .header .menu > ul > li > a {
    font-size: 12px;
  }
}
.header .menu > ul > li > a:hover {
  border-bottom: 1px solid #fff;
}
.header .menu > ul > li.active > a {
  border-bottom: 1px solid #fff;
}
.header h1 {
  font: 700 40px/1 "GothaProBol";
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  text-align: center;
  letter-spacing: 6px;
}
@media screen and (max-width: 420px) {
  .header h1 {
    font-size: 30px;
    top: 60%;
  }
}
.header__searchBarForm {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8%;
  max-width: 700px;
  margin: auto;
  padding: 5px;
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 4px;
}
@media screen and (max-width: 1450px) {
  .header__searchBarForm {
    max-width: 700px;
  }
}
@media screen and (max-width: 1024px) {
  .header__searchBarForm {
    margin: 0 15px;
  }
}
@media screen and (max-width: 1023px) {
  .header__searchBarForm {
    display: none;
  }
}
.header__searchBarForm_number {
  max-width: 680px;
}
.header__searchBarForm_hall {
  height: 260px;
  bottom: 3%;
}
@media screen and (min-width: 1023px) {
  .header .searchBarForm.cruise {
    display: none;
  }
}
.header__search {
  z-index: 999;
  width: 25px;
  height: 25px;
  background: url("/img/icons/search.png") center no-repeat;
}
@media screen and (min-width: 1450px) {
  .header__search {
    margin-right: 27px;
  }
}
@media screen and (min-width: 1430px) {
  .header__search {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1430px) {
  .header__search {
    position: fixed;
    left: 70px;
    top: 15px;
  }
}
.header__block {
  position: absolute;
  top: 60px;
  right: 4%;
  z-index: 999;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1470px) {
  .header__block {
    right: 15px;
  }
}

.reservations-title {
  font-weight: bold;
  text-transform: uppercase;
}

.slick-track {
  margin: auto 0;
}

.footer {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #525252;
}
@media screen and (min-width: 992px) {
  .footer-visible {
    display: none;
  }
}
.footer__list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .footer__list {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 992px) {
  .footer__list {
    display: none;
  }
}
.footer__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__item:nth-child(2) {
  max-width: 355px;
}
@media screen and (max-width: 1024px) {
  .footer__item:nth-child(2) {
    max-width: 235px;
  }
}
@media screen and (max-width: 1024px) {
  .footer__item:nth-child(3) {
    max-width: 200px;
  }
}
.footer__item > a {
  font: 100 16px/1.5 GothaProLig;
  color: #fff;
  border-bottom: 1px solid #fff;
}
.footer-menu > h5, .footer-menu-mobile > h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
}
.footer-menu > ul, .footer-menu-mobile > ul {
  list-style-type: none;
  padding: 0;
}
.footer-menu > ul > li, .footer-menu-mobile > ul > li {
  margin: 6px 0;
}
.footer-menu > ul > li > a, .footer-menu-mobile > ul > li > a {
  font: 100 16px/1.5 GothaProLig;
  color: #fff;
}
@media screen and (max-width: 1060px) {
  .footer-menu > ul > li > a, .footer-menu-mobile > ul > li > a {
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  .footer-menu > ul > li > a, .footer-menu-mobile > ul > li > a {
    font-size: 12px;
  }
}
.footer-menu > ul > li > a:hover, .footer-menu-mobile > ul > li > a:hover {
  color: #9f927f;
}
.footer-inner {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 668px) and (max-width: 1024px) {
  .footer-inner {
    flex-direction: column;
  }
}
.footer-inner > a {
  font-size: 16px;
  color: #9f927f;
}
@media screen and (max-width: 1024px) {
  .footer-inner > a {
    font-size: 14px;
  }
}
.footer-inner svg {
  width: 20px;
  height: 20px;
}
.footer-inner div {
  margin-right: 10px;
}
.footer .logo-radisson {
  display: block;
  max-width: 200px;
  margin: 40px auto 0;
}
@media screen and (max-width: 992px) {
  .footer .logo-radisson {
    max-width: 190px;
    margin: 35px auto;
  }
}

.copyright {
  text-align: right;
  font-size: 12px;
  color: #fff;
}
@media screen and (max-width: 992px) {
  .copyright {
    text-align: center;
  }
}
@media screen and (max-width: 420px) {
  .copyright {
    font-size: 10px;
  }
}

.rules-residence {
  font: 100 16px/1.5 GothaProLig;
  color: #fff;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 1024px) {
  .rules-residence {
    font-size: 14px;
  }
}

.basement__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .basement__list {
    flex-wrap: wrap;
    align-items: flex-end;
  }
}
@media screen and (max-width: 667px) {
  .basement__list {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 667px) {
  .basement__item:nth-child(2) {
    margin: 35px 0;
  }
}
@media screen and (min-width: 992px) {
  .basement__item:nth-child(3), .basement__item:nth-child(4) {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .basement__item:nth-child(4), .basement__item:nth-child(5) {
    flex-basis: 100%;
  }
}

.language-select {
  position: relative;
  z-index: 50;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  /*  @media screen and (max-width: 1430px) {
      right: 15px;
    }*/
}
@media screen and (max-width: 420px) {
  .language-select {
    position: fixed;
    top: 20px;
    right: 0;
  }
}
@media screen and (max-width: 1430px) {
  .language-select {
    /*display: none;*/
    position: fixed;
    top: 21px;
    right: 15px;
  }
}
.language-select .lang_li {
  position: relative;
  display: block;
  text-align: center;
  color: #fff;
}
.language-select .lang_li a {
  display: block;
  padding: 9px 0;
  color: #5a5a5a;
  text-transform: uppercase;
}
.language-select .lang_li a:hover {
  text-decoration: none;
  color: #cc0000;
}

#lang-curr, #lang-curr-burger {
  font-size: 12px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  height: 30px;
  padding: 5px 20px 0;
}
@media screen and (max-width: 1430px) {
  #lang-curr, #lang-curr-burger {
    border-color: transparent;
    line-height: 10px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 420px) {
  #lang-curr, #lang-curr-burger {
    height: 28px;
  }
}

#lang-curr-burger {
  padding: 5px 0 0 0;
}

#lang-list,
#lang-list-burger {
  display: none;
  background-color: #fff;
  position: absolute;
  z-index: 100;
  left: 15px;
  right: 15px;
}
#lang-list:hover a:hover,
#lang-list-burger:hover a:hover {
  background-color: #fff;
}
@media screen and (max-width: 420px) {
  #lang-list,
  #lang-list-burger {
    left: 5px;
    right: 5px;
    top: 35px;
  }
}

.selected-lang .icon {
  color: #fff;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

body.overflow {
  overflow: hidden;
}

/*  BURGER
========================================== */
.hamburger .icon {
  color: #c4d294;
}

.burger {
  width: 35px;
  height: 35px;
  position: fixed;
  top: 10px;
  left: 15px;
  border-radius: 0;
  z-index: 99999;
  background-color: transparent;
}
@media screen and (min-width: 1430px) {
  .burger {
    display: none;
  }
}

.burger span {
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -1.5px;
}

.burger span, .burger span::before, .burger span::after {
  display: block;
  width: 23px;
  height: 2px;
  background-color: #fff;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.burger span::before, .burger span::after {
  position: absolute;
  content: "";
}

.burger span::before {
  top: -6px;
}

.burger span::after {
  top: 6px;
}

.burger.clicked {
  background-color: transparent;
  top: 15px;
  left: 325px;
  transition: 2s;
}

.burger.clicked span {
  background-color: transparent;
}

.burger.clicked span::before {
  -webkit-transform: translateY(6px) rotate(45deg);
  -moz-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

.burger.clicked span::after {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -moz-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}

.burger.clicked span:before, .burger.clicked span:after {
  background-color: #ffffff;
}

.burger:hover {
  cursor: pointer;
}

/*  NAV
========================================== */
nav.hamburger {
  background-color: #928a7d;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -375px;
  height: 100%;
  max-width: 375px;
  width: 100%;
  overflow-y: auto;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (max-width: 667px) {
  nav.hamburger {
    padding-top: 90px;
  }
}
nav.hamburger.show {
  -webkit-transform: translateX(375px);
  -moz-transform: translateX(375px);
  -ms-transform: translateX(375px);
  -o-transform: translateX(375px);
  transform: translateX(375px);
}

nav.hamburger.show .hamburger__list li {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

nav.hamburger.show .hamburger__list li:nth-child(1) {
  transition-delay: 0.15s;
}

nav.hamburger.show .hamburger__list li:nth-child(2) {
  transition-delay: 0.3s;
}

nav.hamburger.show .hamburger__list li:nth-child(3) {
  transition-delay: 0.45s;
}

nav.hamburger.show .hamburger__list li:nth-child(4) {
  transition-delay: 0.6s;
}

nav.hamburger.show .hamburger__list li:nth-child(5) {
  transition-delay: 0.75s;
}

nav.hamburger.show .hamburger__list li:nth-child(6) {
  transition-delay: 0.9s;
}

nav.hamburger.show .hamburger__list li:nth-child(7) {
  transition-delay: 1.05s;
}

nav.hamburger.show .hamburger__list li:nth-child(8) {
  transition-delay: 1.2s;
}

nav.hamburger.show .hamburger__list li:nth-child(9) {
  transition-delay: 1.35s;
}

nav.hamburger.show .about,
nav.hamburger.show .hamburger__social,
nav.hamburger.show .hamburger__sublist {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.85s;
}

@media (min-width: 667px) {
  nav.hamburger {
    padding: 70px 0 0 0;
    margin: auto;
  }
}
nav.hamburger .hamburger__list {
  list-style-type: none;
  max-width: 200px;
  margin: auto;
}

nav.hamburger .hamburger__list li {
  margin-bottom: 25px;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (max-width: 667px) {
  nav.hamburger .hamburger__list li {
    margin-bottom: 25px;
  }
}
nav.hamburger .hamburger__list li:last-of-type {
  margin-bottom: 0;
}

nav.hamburger .hamburger__list li a {
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

nav.hamburger .hamburger__list li a span {
  color: white;
}

nav.hamburger .hamburger__list li a:hover {
  color: #fff;
}

.hamburger__hr {
  margin: 20px 10px;
  border: 0;
  border-top: 1px solid #fff;
}

/*  OVERLAY
========================================== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #b7ac7f;
  opacity: 0;
  visibility: hidden;
}

.overlay.show {
  opacity: 0;
  visibility: visible;
}

.hamburger__inner {
  margin: auto;
}

.hamburger__content {
  max-width: 200px;
  margin: auto;
}
.hamburger__content > a, .hamburger__content > div {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}
.hamburger__content .language-select {
  display: inline-block;
  position: static;
}
@media screen and (min-width: 1430px) {
  .hamburger__content .language-select {
    display: none;
  }
}

nav.hamburger .header__social {
  position: static;
  display: flex;
  justify-content: flex-start;
  height: auto;
  margin: 0;
}
@media screen and (min-width: 667px) {
  nav.hamburger .header__social {
    display: none;
  }
}
nav.hamburger .header__social > .logo {
  margin-left: 25px;
}
nav.hamburger .header__social > .logo img {
  height: 70px;
  width: auto;
}
nav.hamburger #lang-list-burger {
  position: static;
}

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
.nav-tabs {
  flex-wrap: nowrap;
  margin-bottom: 90px;
  border-bottom: 0px solid #dee2e6;
}
.nav-tabs .nav-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
  color: #000;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #a19379;
  border-color: #a19379;
  cursor: text;
}
.nav-tabs .nav-item.show .nav-link:focus, .nav-tabs .nav-item.show .nav-link:hover,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
  color: #fff;
}
.nav-tabs .nav-item {
  margin: 0 30px 0 0;
  border: 1px solid #a19379;
  border-radius: 4px;
}

.tab-content h2 {
  font-size: 26px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #d0c8bb;
}
.tab-content p {
  font-size: 18px;
}
.tab-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0;
}
.tab-content img {
  max-width: 60%;
  margin-bottom: 30px;
}
@media screen and (max-width: 667px) {
  .tab-content img {
    max-width: 100%;
  }
}

.service-list > h4 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 38px;
}
.service-list ul {
  padding: 0 0 0 15px;
}
.service-list ul li {
  list-style-image: url("/img/circle.png");
  font-size: 14px;
  padding-left: 10px;
}
.service-list ul li:not(:last-child) {
  margin-bottom: 10px;
}

.description-object {
  padding-left: 6vw;
}
@media screen and (max-width: 992px) {
  .description-object {
    padding: 40px 0 0 0;
  }
}
.description-object h2 {
  font-size: 30px;
  line-height: 1.1;
  margin-bottom: 0;
}
.description-object p {
  font-size: 18px;
  margin: 25px 0 0;
}
.description-object .parameters__list {
  display: flex;
  margin-top: 25px;
}
.description-object .parameters__list_3d {
  flex-direction: column;
}
.description-object .parameters__item {
  position: relative;
  padding-left: 30px;
  font-size: 14px;
}
.description-object .parameters__item_area:before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  background: url("/img/icons/area.png");
}
.description-object .parameters__item_3d {
  color: #000;
  font-weight: bold;
  font-size: 1.6rem;
}
.description-object .parameters__item_3d:hover {
  text-decoration: underline;
}
.description-object .parameters__item_3d:not(:last-child) {
  margin-bottom: 5px;
}
.description-object .parameters__item_3d:before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 0;
  width: 17px;
  height: 20px;
  background: url("/img/icons/3d-tour.png");
}
.description-object .parameters__item_capacity {
  margin-left: 15px;
  padding-left: 50px;
  border-left: 1px solid #464646;
}
.description-object .parameters__item_capacity:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 15px;
  width: 19px;
  height: 20px;
  background: url("/img/icons/user.png");
}
.description-object ul {
  list-style-type: none;
  padding: 40px 0 0 0;
}
.description-object ul > li {
  padding: 5px 0;
  font-weight: bold;
}
.description-object ul.balloon {
  padding: 0 0 0 15px;
}
.description-object ul.balloon li {
  list-style-image: url("/img/circle.png");
  font-size: 14px;
  padding-left: 10px;
}
.description-object ul.balloon li:not(:last-child) {
  margin-bottom: 20px;
}
.description-object .service-list > h4 {
  font-size: 16px;
  margin: 30px 0 20px 0;
}
.description-object .service-list ul {
  padding: 0 0 0 15px;
}
.description-object .service-list ul li {
  list-style-image: url("/img/circle.png");
  font-size: 14px;
  padding-left: 10px;
}
.description-object .service-list ul li:not(:last-child) {
  margin-bottom: 5px;
}
.description-object > .btn-collapse .icon {
  color: #cc0001;
  font-size: 20px;
}

.advantage .card {
  margin: 0 30px 60px;
}
@media screen and (max-width: 1024px) {
  .advantage .card {
    margin: 0 20px 40px;
  }
}
@media screen and (max-width: 768px) {
  .advantage .card {
    text-align: center;
  }
}
.advantage .card-body h4 {
  font-size: 18px;
  font-weight: 600;
  padding: 25px 0;
  margin: 0;
  text-transform: uppercase;
}
.advantage .card-footer p {
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
}

.placement .card {
  background: transparent;
  padding: 50px 35px 35px;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .placement .card {
    padding: 40px 30px 30px;
  }
}
@media screen and (min-width: 420px) and (max-width: 768px) {
  .placement .card {
    padding: 40px 30px 70px;
  }
}
@media screen and (max-width: 420px) {
  .placement .card {
    padding: 40px 30px 50px;
  }
}
.placement .card:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
.placement .card-around {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}
.placement .card-header h4 {
  color: #fff;
  font-size: 18px;
  padding: 0 0 30px;
  margin: 0;
  text-transform: uppercase;
}
.placement .card-body p {
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.button-block {
  margin: 95px 0;
}
@media screen and (max-width: 1024px) {
  .button-block {
    margin: 70px 0;
  }
}
.button-block__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.button-block__item {
  margin: 0 10px 20px;
  flex-grow: 1;
}
.button-block__item > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid #dddddd;
  border-radius: 4px;
  height: 40px;
  padding: 0 20px;
  font-size: 12px;
  line-height: 1.2;
  text-transform: uppercase;
}
.button-block__item > a:hover {
  border-color: #cc0001;
  color: #cc0001;
}

.kievsq {
  padding: 50px 15px 30px;
  background-color: #928b7d;
}
.kievsq__list {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 667px) {
  .kievsq__list {
    flex-direction: column;
  }
}
.kievsq__item, .kievsq__item a {
  font-size: 13px;
  line-height: 1.3;
  color: #fff;
}
.kievsq__item {
  padding: 0 20px 20px;
}
.kievsq__item:last-child {
  padding-top: 33px;
}
@media screen and (max-width: 667px) {
  .kievsq__item:last-child {
    padding-top: 0;
    text-align: center;
  }
}
.kievsq__item a > span {
  color: #fff;
  font-size: 13px;
  line-height: 1.3;
}
.kievsq__item img {
  max-width: 133px;
}
.kievsq__item a {
  text-decoration: none;
  white-space: nowrap;
  display: block;
}

.slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}

.slider-btn {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 60px;
}
@media screen and (min-width: 1680px) {
  .slider-btn {
    padding: 0;
  }
}
@media screen and (max-width: 1025px) {
  .slider-btn {
    padding: 0;
  }
}
@media screen and (max-width: 1025px) {
  .slider-btn .slick-list {
    padding: 0 30px 0 0;
  }
}
@media screen and (max-width: 769px) {
  .slider-btn .slick-list {
    padding: 0 80px 0 0;
  }
}
@media screen and (max-width: 667px) {
  .slider-btn .slick-list {
    padding: 0 40px 0 0;
  }
}
.slider-btn .slick-slide.slick-active .slider-btn__link:hover {
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.2);
}
.slider-btn__link {
  background-color: #fff;
  display: block;
  text-align: center;
  padding: 0 0 40px;
  margin: 0 14px 11px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0;
}
.slider-btn__link:hover {
  text-decoration: none;
}
.slider-btn__link:hover .slider-btn__btn {
  background-color: #cc0001;
  color: #fff;
}
.slider-btn__link:hover .slider-btn__img {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
@media screen and (max-width: 1025px) {
  .slider-btn__link {
    margin: 0 15px 11px 0;
  }
}
.slider-btn__bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/carousel/btn/3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  font-size: 0;
}
.slider-btn__img {
  position: relative;
  display: inline-block;
  width: 100%;
}
.slider-btn__img h3 {
  position: absolute;
  left: 30px;
  right: 20%;
  top: 0;
  bottom: 0;
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: left;
  text-transform: uppercase;
}
.slider-btn__btn {
  height: 40px;
  line-height: 40px;
  margin: 30px 30px 0;
  border: 1px solid #cc0001;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  color: #cc0001;
  max-width: 250px;
}
@media screen and (min-width: 420px) and (max-width: 667px) {
  .slider-btn__btn {
    margin: 30px auto;
  }
}
.slider-btn__text {
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 30px 30px 0;
}

.thumbnail-slider__bg,
.thumbnail-slider-offer__bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/carousel/btn/3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  font-size: 0;
  margin: 0 15px;
}
.thumbnail-slider__bg:hover .thumbnail-slider__img,
.thumbnail-slider-offer__bg:hover .thumbnail-slider__img {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
@media screen and (max-width: 1025px) {
  .thumbnail-slider__bg:hover,
  .thumbnail-slider-offer__bg:hover {
    margin: 0 15px 0 0;
  }
}
.thumbnail-slider__bg_for,
.thumbnail-slider-offer__bg_for {
  margin: 0;
  border-radius: 0;
}
.thumbnail-slider__text,
.thumbnail-slider-offer__text {
  height: 450px;
  width: 40%;
  display: flex;
  align-items: flex-start;
  margin-left: 4%;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 667px) and (max-width: 1024px) {
  .thumbnail-slider__text,
  .thumbnail-slider-offer__text {
    width: 80%;
  }
}
@media screen and (max-width: 667px) {
  .thumbnail-slider__text,
  .thumbnail-slider-offer__text {
    width: 90%;
  }
}
.thumbnail-slider__text h3,
.thumbnail-slider-offer__text h3 {
  height: 65px;
  font-size: 24px;
  line-height: 1.4;
  color: #fff;
}
.thumbnail-slider__text p,
.thumbnail-slider-offer__text p {
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
}
.thumbnail-slider__text .btn-place,
.thumbnail-slider-offer__text .btn-place {
  margin-top: 45px;
}
.thumbnail-slider__img,
.thumbnail-slider-offer__img {
  position: relative;
  display: inline-block;
  width: 100%;
}
.thumbnail-slider__img .info,
.thumbnail-slider-offer__img .info {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 45px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.thumbnail-slider__img .info h3,
.thumbnail-slider-offer__img .info h3 {
  height: 65px;
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
  align-items: center;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
}
.thumbnail-slider__img .info p,
.thumbnail-slider-offer__img .info p {
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
}
@media screen and (max-width: 768px) {
  .thumbnail-slider-nav,
  .thumbnail-slider-offer-nav {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .thumbnail-slider-nav .slick-list,
  .thumbnail-slider-offer-nav .slick-list {
    padding: 0 30px 0 0;
  }
}
@media screen and (max-width: 769px) {
  .thumbnail-slider-nav .slick-list,
  .thumbnail-slider-offer-nav .slick-list {
    padding: 0 80px 0 0;
  }
}
@media screen and (max-width: 667px) {
  .thumbnail-slider-nav .slick-list,
  .thumbnail-slider-offer-nav .slick-list {
    padding: 0 40px 0 0;
  }
}
.thumbnail-slider-nav .slick-prev,
.thumbnail-slider-nav .slick-next,
.thumbnail-slider-offer-nav .slick-prev,
.thumbnail-slider-offer-nav .slick-next {
  position: absolute;
  z-index: 100;
  height: 101%;
  background-color: #fff;
  width: 4%;
}
.thumbnail-slider-nav .slick-prev:before,
.thumbnail-slider-nav .slick-next:before,
.thumbnail-slider-offer-nav .slick-prev:before,
.thumbnail-slider-offer-nav .slick-next:before {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 25px;
  height: 51px;
  margin-top: -25px;
}
.thumbnail-slider-nav .slick-prev,
.thumbnail-slider-offer-nav .slick-prev {
  left: 0;
}
.thumbnail-slider-nav .slick-prev:before,
.thumbnail-slider-offer-nav .slick-prev:before {
  content: "";
  left: 0;
  background: url("/img/arrow-grey-left.png") center;
}
.thumbnail-slider-nav .slick-next,
.thumbnail-slider-offer-nav .slick-next {
  right: 0;
}
.thumbnail-slider-nav .slick-next:before,
.thumbnail-slider-offer-nav .slick-next:before {
  content: "";
  right: 0;
  background: url("/img/arrow-grey-right.png") center;
}

.thumbnail-slider-inner__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 0;
  margin: 0 10px;
}
@media screen and (max-width: 1025px) {
  .thumbnail-slider-inner__bg:hover {
    margin: 0 15px 0 0;
  }
}
.thumbnail-slider-inner__bg_for {
  margin: 0;
  border-radius: 0;
}
.thumbnail-slider-inner__img {
  position: relative;
  display: inline-block;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .thumbnail-slider-inner-nav {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .thumbnail-slider-inner-nav .slick-list {
    padding: 0 30px 0 0;
  }
}
@media screen and (max-width: 769px) {
  .thumbnail-slider-inner-nav .slick-list {
    padding: 0 80px 0 0;
  }
}
@media screen and (max-width: 667px) {
  .thumbnail-slider-inner-nav .slick-list {
    padding: 0 40px 0 0;
  }
}
.thumbnail-slider-inner-nav .slick-prev,
.thumbnail-slider-inner-nav .slick-next {
  position: absolute;
  z-index: 100;
  height: 102%;
  background-color: #fff;
  width: 7%;
}
.thumbnail-slider-inner-nav .slick-prev:before,
.thumbnail-slider-inner-nav .slick-next:before {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 10px;
  height: 20px;
  margin-top: -10px;
}
.thumbnail-slider-inner-nav .slick-prev {
  left: 0;
}
.thumbnail-slider-inner-nav .slick-prev:before {
  content: "";
  left: 0;
  background: url("/img/arrow-grey-left.png") center;
  background-size: cover;
}
.thumbnail-slider-inner-nav .slick-next {
  right: 0;
}
.thumbnail-slider-inner-nav .slick-next:before {
  content: "";
  right: 0;
  background: url("/img/arrow-grey-right.png") center;
  background-size: cover;
}

#temporary .slick-prev,
#temporary .slick-next {
  position: absolute;
  z-index: 100;
  height: 101%;
  background-color: #fff;
  width: 4%;
}
#temporary .slick-prev:before,
#temporary .slick-next:before {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 25px;
  height: 51px;
  margin-top: -25px;
}
#temporary .slick-prev {
  left: 0;
}
#temporary .slick-prev:before {
  content: "";
  left: 0;
  background: url("/img/arrow-grey-left.png") center;
}
#temporary .slick-next {
  right: 0;
}
#temporary .slick-next:before {
  content: "";
  right: 0;
  background: url("/img/arrow-grey-right.png") center;
}

.container_temporary {
  max-width: 750px;
}

.accordion {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-bottom: 35px;
}
@media screen and (min-width: 992px) {
  .accordion {
    display: none;
  }
}
.accordion .link {
  cursor: pointer;
  display: block;
  padding: 25px 0;
  color: #fff;
  font-size: 14px;
  border-top: 1px solid #CCC;
  position: relative;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.accordion .link span {
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 30px;
  color: #fff;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.accordion li.open > .link {
  padding-bottom: 10px;
}
.accordion li.open span.icon-down-open-mini {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*
.accordion li.open .link {
  color: #AA00FF
}

.accordion li.open span {
  color: #AA00FF
}
*/
.footer-menu-mobile {
  display: none;
  margin-bottom: 25px;
}

#reservation label,
#reservationhall label {
  margin-bottom: 0;
  font-size: 12px;
  text-transform: uppercase;
}
#reservation label > span,
#reservationhall label > span {
  font-size: 12px;
  margin-left: 50px;
}
#reservation .btn,
#reservationhall .btn {
  width: 140px;
  /*height: 50px;*/
  background-color: #cc0001;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}
@media screen and (max-width: 1023px) {
  #reservation .btn,
  #reservationhall .btn {
    margin: auto;
    height: 50px;
  }
}
#reservation .form-group,
#reservationhall .form-group {
  /*margin-right: 35px;*/
  padding: 0.5rem;
  margin-bottom: 0;
  border: 1px solid #d8d8d8;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  #reservation .form-group,
  #reservationhall .form-group {
    margin-right: 0;
    border: none;
  }
}
#reservation .form-group_daterange,
#reservationhall .form-group_daterange {
  position: relative;
  /*padding-left: 0;*/
}
#reservation .form-control_daterange,
#reservation .form-control_text,
#reservationhall .form-control_daterange,
#reservationhall .form-control_text {
  border: 0;
  border-bottom: 1px solid #828282;
  border-radius: 0;
  padding-left: 0;
}
#reservation .form-control_daterange,
#reservationhall .form-control_daterange {
  padding-right: 30px;
  background: url(/img/icons/form-arrow.png) center right no-repeat;
}
#reservation textarea.form-control,
#reservationhall textarea.form-control {
  border: 1px solid #828282;
  height: 195px;
  margin-top: 3px;
}
#reservation .jq-selectbox.jqselect.form-control,
#reservationhall .jq-selectbox.jqselect.form-control {
  padding: 0;
  border: 0;
}
#reservation .jq-selectbox__select-text,
#reservationhall .jq-selectbox__select-text {
  line-height: 30px;
}
#reservation .jq-selectbox__select,
#reservationhall .jq-selectbox__select {
  height: 34px;
  padding: 0 45px 0 0;
  border: 0;
  border-bottom: 1px solid #828282;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  color: #000;
  font-size: 14px;
  text-shadow: none;
}
#reservation .jq-selectbox__trigger,
#reservationhall .jq-selectbox__trigger {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 34px;
  height: 34px;
  border-left: 0;
  background-color: transparent;
  border-radius: 2px;
}
#reservation .jq-selectbox__trigger-arrow,
#reservationhall .jq-selectbox__trigger-arrow {
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  border-top: 0px solid #fff;
  border-right: 0px solid transparent;
  border-left: 0px solid transparent;
  font-size: 34px;
  color: #b30b0a;
  display: inline-block;
  width: 25px;
  line-height: 30px;
}
#reservation .jq-selectbox__trigger-arrow:before,
#reservationhall .jq-selectbox__trigger-arrow:before {
  content: "\e80d";
  font-family: "fontello";
  width: 25px;
  text-align: center;
  line-height: 1em;
}
#reservation .jq-selectbox:hover .jq-selectbox__trigger-arrow,
#reservationhall .jq-selectbox:hover .jq-selectbox__trigger-arrow {
  border-top-color: #fff;
}
#reservation .jq-selectbox li.selected,
#reservationhall .jq-selectbox li.selected {
  background-color: #e2e8eb;
  color: #000;
}
#reservation .jq-selectbox li,
#reservationhall .jq-selectbox li {
  min-height: 18px;
  padding: 5px 10px 6px;
  color: #000;
  font-size: 14px;
}
#reservation .jq-selectbox li:hover,
#reservationhall .jq-selectbox li:hover {
  background-color: #e2e8eb;
  color: #000;
}
#reservation .jq-selectbox li:not(:last-child),
#reservationhall .jq-selectbox li:not(:last-child) {
  border-bottom: 1px solid #e2e8eb;
}
#reservation .guests,
#reservationhall .guests {
  position: relative;
  height: 30px;
  padding-bottom: 0;
  border-bottom: 1px solid #828282;
  display: flex;
  align-items: center;
}
#reservation .guests:before,
#reservationhall .guests:before {
  content: "\e80d";
  position: absolute;
  top: -4;
  right: 0;
  height: 30px;
  font-size: 34px;
  color: #b30b0a;
  font-family: "fontello";
  width: 25px;
  text-align: center;
  line-height: 1em;
}
#reservation .guests:hover .guests__list,
#reservationhall .guests:hover .guests__list {
  opacity: 1;
}
#reservation .guests__list,
#reservationhall .guests__list {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 4px;
}
@media screen and (max-width: 1024px) {
  #reservation .guests__list,
  #reservationhall .guests__list {
    left: -20px;
    right: -20px;
  }
}
@media screen and (max-width: 1023px) {
  #reservation .guests__list,
  #reservationhall .guests__list {
    left: 0;
    right: auto;
  }
}
#reservation .guests__item,
#reservationhall .guests__item {
  display: flex;
  padding: 15px 5px;
}
#reservation .guests__item:last-child,
#reservationhall .guests__item:last-child {
  padding: 0.5rem;
}
#reservation .guests__item input,
#reservationhall .guests__item input {
  width: 30px;
  margin-left: 10px;
}

#block-reservationhall {
  padding: 0px;
  display: none;
  z-index: 9999;
  position: absolute;
  left: 0;
  right: 0;
  top: 800px;
  height: 300px;
}

#open-search {
  display: block;
  /*

  background: url(/img/icons/form-arrow.png) center right no-repeat;
  color: #000;

  &:hover {
      color: #000;
  }

  */
  background: #cc0001;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  border: 1px solid #fff;
  border-radius: 4px;
}

#reservationhall {
  width: 1100px;
  margin: auto;
  border: 1px solid #707070;
  border-top: none;
  background-color: #fff;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px;
}
@media screen and (max-width: 1450px) {
  #reservationhall {
    max-width: 1000px;
  }
}
@media screen and (max-width: 1023px) {
  #reservationhall {
    max-width: 100%;
    border: 0;
    padding: 0;
  }
}
#reservationhall h5 {
  font-size: 30px;
  margin-bottom: 30px;
}
#reservationhall p {
  font-size: 14px;
  margin-bottom: 0;
}
#reservationhall .btn {
  width: 100%;
  height: 50px;
  background-color: #cc0001;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
}
@media screen and (max-width: 1023px) {
  #reservationhall .btn {
    margin: auto;
    width: 300px;
  }
}
#reservationhall .form-group {
  margin: 0;
}

.reservation__list {
  display: flex;
  /*align-items: flex-end;*/
  /*justify-content: space-between;*/
  flex-wrap: wrap;
}
@media screen and (max-width: 1023px) {
  .reservation__list {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
    border: 1px solid #c4c4c4;
  }
}
@media screen and (min-width: 1023px) {
  .reservation__list {
    max-width: 700px;
    margin: auto;
  }
}
.reservation__item {
  display: flex;
  /*flex-direction: column;*/
}
.reservation__item:not(:last-child) {
  flex-basis: calc((100% - 150px) / 2);
}
@media screen and (max-width: 1023px) {
  .reservation__item {
    margin-bottom: 40px;
  }
}

.reservationhall__list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 1023px) {
  .reservationhall__list {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
    border: 1px solid #c4c4c4;
  }
}
.reservationhall__item {
  display: flex;
  flex-direction: column;
  flex-basis: 33.3333333333%;
}
.reservationhall__item:nth-child(2) {
  padding: 0 30px;
}
@media screen and (max-width: 1023px) {
  .reservationhall__item:nth-child(2) {
    padding: 0;
  }
}
@media screen and (max-width: 1023px) {
  .reservationhall__item {
    margin-bottom: 40px;
  }
}

.frame-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 130px;
}
@media screen and (max-width: 1023px) {
  .frame-container {
    top: 350px;
  }
}

.modal_search {
  background-color: rgba(247, 247, 247, 0.91);
}
@media screen and (max-width: 667px) {
  .modal_search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.modal_search .modal {
  z-index: 9999;
}
.modal_search .modal-content {
  padding: 60px 20px 0;
  border: 0;
  background-color: transparent;
}
@media screen and (min-width: 420px) and (max-width: 1024px) {
  .modal_search .modal-content {
    box-shadow: none;
    border: 0;
    padding: 20px 0 0;
  }
}
@media screen and (max-width: 420px) {
  .modal_search .modal-content {
    box-shadow: none;
    border: 0;
    padding: 20px 0;
  }
}
.modal_search .modal-content .close {
  margin-top: 8px;
  margin-right: 0;
  opacity: 1;
  color: #9f282a;
  font-weight: 100;
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
  font-size: 20px;
  text-shadow: none;
  /* width: 35px; */
  z-index: 90;
  line-height: 1;
  /* height: 45px;*/
}
.modal_search .modal-content .close:not(:disabled):not(.disabled):focus, .modal_search .modal-content .close:not(:disabled):not(.disabled):hover {
  color: #000;
  opacity: 1;
}
.modal_search .modal-dialog {
  max-width: 80% !important;
  margin: 0 auto;
}
@media screen and (min-width: 420px) and (max-width: 1024px) {
  .modal_search .modal-dialog {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 420px) {
  .modal_search .modal-dialog {
    max-width: 90% !important;
  }
}
.modal_search .modal-body {
  padding: 15px 15px 0;
}
.modal_search .modal-header {
  padding: 15px;
  border-bottom: none;
}
.modal_search .modal-title {
  text-align: center;
  margin: 0 auto 35px;
  padding: 0;
  font: 700 28px/1 "Playfair Display", serif;
  text-transform: uppercase;
  color: #000;
}
@media screen and (max-width: 667px) {
  .modal_search .modal-title {
    margin: 0 auto 5px;
  }
}
.modal_search textarea {
  height: 100px;
}
@media screen and (min-width: 420px) and (max-width: 667px) {
  .modal_search textarea {
    height: 80px;
  }
}
@media screen and (max-width: 420px) {
  .modal_search textarea {
    height: 120px;
  }
}
.modal_search .form-control {
  max-width: 100%;
  color: #000;
  background-color: #fff;
}

.loader-here:before {
  background: transparent url("/css/loader.png") center center no-repeat;
  content: "";
  position: absolute;
  right: 100%;
  width: 32px;
  height: 32px;
}

.loader-here.loader-disabled:before {
  display: none;
}

#resultSearch h2 {
  font: 700 25px/1 "GothaProBol";
  margin: 85px 0 80px;
}
#resultSearch a {
  display: block;
  font: 700 18px/1 "GothaProBol";
  margin: 0 0 33px;
  color: #000;
  text-decoration: underline;
}
@media screen and (max-width: 667px) {
  #resultSearch a {
    margin: 30px 0;
  }
}
#resultSearch p {
  font-size: 14px;
}
#resultSearch img {
  width: 300px;
  height: 200px;
}
#resultSearch .row_result {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  #resultSearch .row_result {
    margin: 30px 0 30px;
  }
}

input[type=search]::-webkit-input-placeholder {
  color: #000;
}

input[type=search]:-ms-input-placeholder {
  color: #000;
}

input[type=search]:-ms-input-placeholder {
  color: #000;
}

input[type=search]::placeholder {
  color: #000;
}

#search .form-control {
  color: #000;
  border: 0;
  background-color: transparent;
  padding: 0 0 8px;
  font-size: 20px;
}
#search .row_search {
  border-top: 2px solid #b3b3b3;
}
#search small {
  color: #b2b3b5;
  margin-top: 6px;
  display: block;
  font-size: 11px;
}

form[id^=reservationhall] .btn {
  width: 100% !important;
  height: 50px;
}

.youtube-video-wrapper {
  width: 720px;
  margin: auto;
  position: relative;
  height: 480px;
}
@media screen and (max-width: 768px) {
  .youtube-video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: auto;
    overflow: hidden;
  }
}
.youtube-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
.header-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*  @media screen and (max-width: 425px) {
      height: 100%;
      //left: 0;
      //margin-left: -65%;
      //position: absolute;
      //width: auto;
      //top: 0;
      //right: 0;
      //bottom: 0;
      width: 100%;
      transform: scale(2.3);
    }*/
}

/* ATTENTION! the scss compiler works. can not add styles to /css/main.css */