#temporary {
  .slick-prev,
  .slick-next {
    position: absolute;
    z-index: 100;
    height: 101%;
    background-color: #fff;
    width: 4%;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      bottom: 0;
      width: 25px;
      height: 51px;
      margin-top: -25px;

    }
  }

  .slick-prev {
    left: 0;
    &:before {
      content: '';
      left: 0;
      background: url("/img/arrow-grey-left.png") center;

    }
  }

  .slick-next {
    right: 0;
    &:before {
      content: '';
      right: 0;
      background: url("/img/arrow-grey-right.png") center;

    }
  }
}
.container_temporary {
  max-width: 750px;
}