.thumbnail-slider-inner {

  &__bg {
    //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/carousel/btn/3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //border-radius: 4px 4px 0 0;
    font-size: 0;
    margin: 0 10px;
    &:hover {
      .thumbnail-slider__img {
        //background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      }
      @media screen and (max-width: 1025px) {
        margin: 0 15px 0 0; //
      }
    }
    &_for {
      margin: 0;
      border-radius: 0;
    }
  }

  &__img {
    position: relative;
    display: inline-block;
    width: 100%;

  }

  &-for {

    @extend %slick-dots;
  }

  &-nav {

    @media screen and (max-width: 768px) {
      display: none;
    }

    .slick-list {
      @media screen and (max-width: 1025px) {
        padding: 0 30px 0 0;
      }

      @media screen and (max-width: 769px) {
        padding: 0 80px 0 0;
      }

      @media screen and (max-width: 667px) {
        padding: 0 40px 0 0;
      }

    }

    @extend %slick-dots;
    @extend %slick-arrow;

    .slick-prev,
    .slick-next {
      position: absolute;
      z-index: 100;
      height: 102%;
      background-color: #fff;
      width: 7%;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        bottom: 0;
        width: 10px;
        height: 20px;
        margin-top: -10px;

      }
    }

    .slick-prev {
      left: 0;
      &:before {
        content: '';
        left: 0;
        background: url("/img/arrow-grey-left.png") center;

        background-size: cover;
      }
    }

    .slick-next {
      right: 0;
      &:before {
        content: '';
        right: 0;
        background: url("/img/arrow-grey-right.png") center;

        background-size: cover;
      }
    }

  }

}