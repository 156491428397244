
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

body.overflow {
  overflow: hidden;
}

/*  BURGER
========================================== */
.hamburger .icon {
  color: #c4d294;
}

.burger {
  //width: 50px;
  width: 35px;
  height: 35px;
  position: fixed;
  top: 10px;
  left: 15px;
  border-radius: 0;
  z-index: 99999;
  background-color: transparent;
  //position: relative;
  //top: 2px;
  //right: 0;

  @media screen and (min-width: 1430px) {
    display: none;
  }
}

.burger span {
  //margin-top: 9px;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  //margin-left: -15px;
  margin-left: -12px;
  margin-top: -1.5px;
}

.burger span, .burger span::before, .burger span::after {
  display: block;
  width: 23px;
  height: 2px;
  background-color: #fff;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.burger span::before, .burger span::after {
  position: absolute;
  content: "";
}

.burger span::before {
  top: -6px;
}

.burger span::after {
  top: 6px;
}

.burger.clicked {
  background-color: transparent;
  top: 15px;//
  left: 325px;
  transition: 2s;
}

.burger.clicked span {
  background-color: transparent;
}

.burger.clicked span::before {
  -webkit-transform: translateY(6px) rotate(45deg);
  -moz-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

.burger.clicked span::after {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -moz-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}

.burger.clicked span:before, .burger.clicked span:after {
  background-color: #ffffff;
}

.burger:hover {
  cursor: pointer;
}

/*  NAV
========================================== */
nav.hamburger {
  background-color: #928a7d;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -375px;
  height: 100%;
  max-width: 375px;
  width: 100%;
  //padding: 100px 40px 60px 40px;
  overflow-y: auto;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (max-width: 667px) {
  nav.hamburger {
    padding-top: 90px;
  }
}

nav.hamburger.show {
  -webkit-transform: translateX(375px);
  -moz-transform: translateX(375px);
  -ms-transform: translateX(375px);
  -o-transform: translateX(375px);
  transform: translateX(375px);
}

nav.hamburger.show .hamburger__list li {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

nav.hamburger.show .hamburger__list li:nth-child(1) {
  transition-delay: 0.15s;
}

nav.hamburger.show .hamburger__list li:nth-child(2) {
  transition-delay: 0.3s;
}

nav.hamburger.show .hamburger__list li:nth-child(3) {
  transition-delay: 0.45s;
}

nav.hamburger.show .hamburger__list li:nth-child(4) {
  transition-delay: 0.6s;
}

nav.hamburger.show .hamburger__list li:nth-child(5) {
  transition-delay: 0.75s;
}

nav.hamburger.show .hamburger__list li:nth-child(6) {
  transition-delay: 0.9s;
}

nav.hamburger.show .hamburger__list li:nth-child(7) {
  transition-delay: 1.05s;
}

nav.hamburger.show .hamburger__list li:nth-child(8) {
  transition-delay: 1.2s;
}

nav.hamburger.show .hamburger__list li:nth-child(9) {
  transition-delay: 1.35s;
}

nav.hamburger.show .about,
nav.hamburger.show .hamburger__social,
nav.hamburger.show .hamburger__sublist {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  transition-delay: .85s;
}

@media (min-width: 667px) {
  nav.hamburger {
    padding: 70px 0 0 0;
    margin: auto;
  }
}

nav.hamburger .hamburger__list {
  list-style-type: none;
  max-width: 200px;
  margin: auto;
}

nav.hamburger .hamburger__list li {
  margin-bottom: 25px;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (max-width: 667px) {
  nav.hamburger .hamburger__list li {
    margin-bottom: 25px;
  }
}

nav.hamburger .hamburger__list li:last-of-type {
  margin-bottom: 0;
}

nav.hamburger .hamburger__list li a {
  font-size: 11px; //
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  display: block;

  padding: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

nav.hamburger .hamburger__list li a span {
  color: #ffff;
}

nav.hamburger .hamburger__list li a:hover {
  color: #fff;
}

.hamburger__hr {
  margin: 20px 10px;
  border: 0;
  border-top: 1px solid #fff;
}

/*  OVERLAY
========================================== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #b7ac7f;
  opacity: 0;
  visibility: hidden;
}

.overlay.show {
  opacity: 0;
  visibility: visible;
}

.hamburger__inner {
  //width: max-content;
  margin: auto;
}

.hamburger__content {
  max-width: 200px;
  margin: auto;

  & > a,
  & > div {
    font-size: 12px;
    color: #fff;
    text-decoration: none;//
  }


  & .language-select {
    display: inline-block;
    position: static;
    @media screen and (min-width: 1430px) {
      display: none;
    }





  }

}
nav.hamburger {

  & .header__social {
    position: static;
    display: flex;
    justify-content: flex-start;
    height: auto;
    margin: 0;
    @media screen and (min-width: 667px) {
      display: none;
    }

    & > .logo {
      margin-left: 25px;
      img {
        height: 70px;
        width: auto;
      }
    }

  }

  #lang-list-burger {

      position: static;

  }
}

@media screen and (max-width: 768px) {
    .w-sm-100 {
        width: 100% !important;
    }
}
