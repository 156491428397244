.footer {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #525252;


  &-visible {
    @media screen and (min-width: 992px){
      display: none;
    }
  }


  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin-bottom: 30px;

    @media screen and (min-width: 992px) and (max-width: 1024px) {
      margin-bottom: 80px;
    }
    @media screen  and (max-width: 992px) {
      display: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    //flex-basis: 20%;

    &:nth-child(2) {
      max-width: 355px;
      @media screen and (max-width: 1024px) {
        max-width: 235px;
      }
    }

    &:nth-child(3) {

      @media screen and (max-width: 1024px) {
        max-width: 200px;
      }
    }

    & > a {
      font: 100 16px/1.5 GothaProLig;
      color: #fff;
      border-bottom: 1px solid #fff;
    }

  }
  &-menu,
  &-menu-mobile{

    & > h5 {
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 20px;
    }
    & > ul {
      list-style-type: none;
      padding: 0;
      & > li {
        margin: 6px 0;

        & > a {
          font: 100 16px/1.5 GothaProLig;
          color: #fff;
          @media screen and (max-width: 1060px) {
            font-size: 14px;
          }

          @media screen and (max-width: 992px) {
            font-size: 12px;
          }

          &:hover {
            color: #9f927f;
          }
        }
      }
    }
  }

  &-menu-mobile {
   & > ul {
      //margin-top: -20px;
    }
  }

  &-inner {
    display: flex;
    align-items: center;

    @media screen and (min-width: 668px) and (max-width: 1024px) {
      flex-direction: column;
    }

    & > a {
      font-size: 16px;
      color: #9f927f;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }

    }

    svg {
      width: 20px;
      height: 20px;
    }

    div {
      margin-right: 10px;
    }
  }

  .logo-radisson {
    display: block;
    max-width: 200px;
    margin: 40px auto 0;
    @media screen and (max-width: 992px) {

      max-width: 190px;
      margin: 35px auto;
    }
  }
}

.copyright {
  text-align: right;
  font-size: 12px; //
  color: #fff;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
  @media screen and (max-width: 420px) {
    font-size: 10px;
  }

}

.rules-residence {
  font: 100 16px/1.5 GothaProLig;
  color: #fff;
  border-bottom: 1px solid #fff;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }


}


.basement {
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 992px) {
     flex-wrap: wrap;
      align-items: flex-end;
    }
    @media screen and (max-width: 667px) {
      flex-direction: column;
      align-items: center;
    }
  }


  &__item{

    &:nth-child(2) {
      @media screen and (max-width: 667px) {
        margin: 35px 0;
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
    &:nth-child(4),
    &:nth-child(5) {
      @media screen and (max-width: 992px) {
        flex-basis: 100%;
      }
    }




  }
}
