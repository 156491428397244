.placement {

  .card {
    background: transparent;
    padding: 50px 35px 35px;
    margin-bottom: 30px;


    @media screen and (min-width: 768px) and (max-width: 1024px){
      padding: 40px 30px 30px;
    }
    @media screen and (min-width: 420px) and (max-width: 768px){
      padding: 40px 30px 70px;
    }
    @media screen and (max-width: 420px){
      padding: 40px 30px 50px;
    }



    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
    }

    &-around {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
    }

    &-header {
      h4 {
        color: #fff;
        font-size: 18px;
        padding: 0 0 30px;
        margin: 0;
        text-transform: uppercase;
      }
    }
    &-body {

      p {
        color: #fff;
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
      }
    }

  }
}