input[type="search"]::-webkit-input-placeholder {
  color: #000;
}

input[type="search"]:-ms-input-placeholder {
  color: #000;
}

input[type="search"]:-ms-input-placeholder {
  color: #000;
}

input[type="search"]::placeholder {
  color: #000;
}



#search {

  & .form-control {
    //color: #8f8a86;
    color: #000;
    border: 0;
    background-color: transparent;
    padding: 0 0 8px;
    font-size: 20px;
  }

  .row_search {
    border-top: 2px solid #b3b3b3;
  }

  small {
    color: #b2b3b5;
    margin-top: 6px;
    display: block;
    font-size: 11px;
  }
}


form[id^='reservationhall'] .btn {
  width: 100%!important;
  height: 50px;
}



