@font-face {
  font-family: 'GothaProBla';
  src: url('/fonts/GothaProBla.eot');
  src: local('GothaProBla'), url('/fonts/GothaProBla.woff') format('woff'), url('/fonts/GothaProBla.ttf') format('truetype'), url('/fonts/GothaProBla.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothaProBlaIta';
  src: url('/fonts/GothaProBlaIta.eot');
  src: local('GothaProBlaIta'), url('/fonts/GothaProBlaIta.woff') format('woff'), url('/fonts/GothaProBlaIta.ttf') format('truetype'), url('/fonts/GothaProBlaIta.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}//

@font-face {
  font-family: 'GothaProBol';
  src: url('/fonts/GothaProBol.eot');
  src: local('GothaProBol'), url('/fonts/GothaProBol.woff') format('woff'), url('/fonts/GothaProBol.ttf') format('truetype'), url('/fonts/GothaProBol.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothaProBolIta';
  src: url('/fonts/GothaProBolIta.eot');
  src: local('GothaProBolIta'), url('/fonts/GothaProBolIta.woff') format('woff'), url('/fonts/GothaProBolIta.ttf') format('truetype'), url('/fonts/GothaProBolIta.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GothaProLig';
  src: url('/fonts/GothaProLig.eot');
  src: local('GothaProLig'), url('/fonts/GothaProLig.woff') format('woff'), url('/fonts/GothaProLig.ttf') format('truetype'), url('/fonts/GothaProLig.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothaProLigIta';
  src: url('/fonts/GothaProLigIta.eot');
  src: local('GothaProLigIta'), url('/fonts/GothaProLigIta.woff') format('woff'), url('/fonts/GothaProLigIta.ttf') format('truetype'), url('/fonts/GothaProLigIta.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GothaProMed';
  src: url('/fonts/GothaProMed.eot');
  src: local('GothaProMed'), url('/fonts/GothaProMed.woff') format('woff'), url('/fonts/GothaProMed.ttf') format('truetype'), url('/fonts/GothaProMed.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothaProMedIta';
  src: url('/fonts/GothaProMedIta.eot');
  src: local('GothaProMedIta'), url('/fonts/GothaProMedIta.woff') format('woff'), url('/fonts/GothaProMedIta.ttf') format('truetype'), url('/fonts/GothaProMedIta.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GothaProNarBol';
  src: url('/fonts/GothaProNarBol.eot');
  src: local('GothaProNarBol'), url('/fonts/GothaProNarBol.woff') format('woff'), url('/fonts/GothaProNarBol.ttf') format('truetype'), url('/fonts/GothaProNarBol.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothaProNarMed';
  src: url('/fonts/GothaProNarMed.eot');
  src: local('GothaProNarMed'), url('/fonts/GothaProNarMed.woff') format('woff'), url('/fonts/GothaProNarMed.ttf') format('truetype'), url('/fonts/GothaProNarMed.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothaProReg';
  src: url('/fonts/GothaProReg.eot');
  src: local('GothaProReg'), url('/fonts/GothaProReg.woff') format('woff'), url('/fonts/GothaProReg.ttf') format('truetype'), url('/fonts/GothaProReg.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
