.advantage {


  .card {
    margin: 0 30px 60px;
    @media screen and (max-width: 1024px) {
      margin: 0 20px 40px;
    }
    @media screen and (max-width: 768px) {
      text-align: center;

    }

    &-body {
      h4 {
        font-size: 18px;
        font-weight: 600;//
        padding: 25px 0;
        margin: 0;
        text-transform: uppercase;
      }
    }
    &-footer {
      p {
        font-size: 16px;
        line-height: 1.4;
        margin: 0;
      }
    }

  }



}