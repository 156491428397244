.kievsq {
  padding: 50px 15px 30px;
  background-color: #928b7d;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 667px) {
      flex-direction: column;
    }
  }

  &__item,
  &__item a {
    font-size: 13px;
    line-height: 1.3;
    color: #fff;
  }

  &__item {
    padding: 0 20px 20px;

    &:last-child {
      padding-top: 33px;

      @media screen and (max-width: 667px) {
        padding-top: 0;
        text-align: center;

      }
    }

    & a > span {
      color: #fff;
      font-size: 13px;
      line-height: 1.3;
    }

    &  img {
      max-width: 133px;
    }
    &  a {
      text-decoration: none;
      white-space: nowrap;
      display: block;
    }

  }

}
