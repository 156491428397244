
.modal_search {
  //background-color: #fff;
  background-color: rgba(247, 247, 247, 0.91);

  @media screen and (max-width: 667px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal {
    z-index: 9999;
    &-content {
      padding: 60px 20px 0;
      border: 0;
      background-color: transparent;

      @media screen and (min-width: 420px) and (max-width: 1024px) {
        box-shadow: none;
        border: 0;
        padding: 20px 0 0;
      }
      @media screen and (max-width: 420px) {
        box-shadow: none;
        border: 0;
        padding: 20px 0;
      }
      & .close {
        margin-top: 8px;
        margin-right: 0;
        opacity: 1;
        color: #9f282a;
        font-weight: 100;
        /* position: absolute; */
        /* top: 0; */
        /* right: 0; */
        font-size: 20px;
        text-shadow: none;
        /* width: 35px; */
        z-index: 90;
        line-height: 1;
        /* height: 45px;*/

        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):hover {
          color: #000;
          opacity: 1;
        }

      }
    }
    &-dialog {
      max-width: 80% !important;
      margin: 0 auto;

      @media screen and (min-width: 420px) and (max-width: 1024px) {
        max-width: 80% !important;
      }
      @media screen and (max-width: 420px) {
        max-width: 90% !important;
      }
    }
    &-body {
      padding: 15px 15px 0;
    }
    &-header {
      padding: 15px;
      border-bottom: none;
    }
    &-title {
      text-align: center;
      margin: 0 auto 35px;
      padding: 0;
      font: 700 28px/1 'Playfair Display', serif;
      text-transform: uppercase;
      color: #000;

      @media screen and (max-width: 667px) {
        margin: 0 auto 5px;
      }
    }
  }

  textarea {
    height: 100px;

    @media screen and (min-width: 420px) and (max-width: 667px) {
      height: 80px;
    }

    @media screen and (max-width: 420px) {
      height: 120px;
    }
  }

  .form-control {
    max-width: 100%;
    color: #000;
    background-color: #fff;

  }

}

.loader-here:before{
    background: transparent url('/css/loader.png') center center no-repeat;
    content: "";
    position: absolute;
    right: 100%;
    width: 32px;
    height: 32px;
}

.loader-here.loader-disabled:before{
    display:none;
}

#resultSearch {

  h2 {
    font: 700 25px/1 'GothaProBol';
    margin: 85px 0 80px;
  }

  a {
    display: block;
    font: 700 18px/1 'GothaProBol';
    margin: 0 0 33px;
    color: #000;
    text-decoration: underline;
    @media screen and (max-width: 667px) {
      margin: 30px 0;
    }
  }

  p {
    font-size: 14px;
  }

  img {
    width: 300px;
    height: 200px;
  }

  .row_result {
    margin-bottom: 60px;
    @media screen and (max-width: 768px) {
      margin: 30px 0 30px;
    }

  }

}
