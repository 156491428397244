
.accordion {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-bottom: 35px;

  @media screen and (min-width: 992px) {
    display: none;
  }

  .link {
    cursor: pointer;
    display: block;
    padding: 25px 0;
    color: #fff;
    font-size: 14px;
    border-top: 1px solid #CCC;
    position: relative;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;

    span {
      position: absolute;
      top: 16px;
      right: 0;
      font-size: 30px;
      color: #fff;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;

    }

  }

  li.open {
    & > .link {
      padding-bottom: 10px;
    }
  }

  li.open span.icon-down-open-mini {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

/*
.accordion li.open .link {
  color: #AA00FF
}

.accordion li.open span {
  color: #AA00FF
}
*/

.footer-menu-mobile {
  display: none;
  margin-bottom: 25px;

}

