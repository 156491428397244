$color-text: #fff;
$color-border: #fff;
$color-bg: #fff;
$color-lang: #5a5a5a;
$color-lang-hover: #cc0000;

.language-select {
  position: relative;
  //position: absolute;
  //top: 60px;
  //right: 4%;
  z-index: 50;
  display: inline-block;
  cursor: pointer;
  color: $color-text;
  @media screen and (max-width: 420px) {
      position: fixed;
      top: 20px;
      right: 0;
  }

  /*  @media screen and (max-width: 1430px) {
      right: 15px;
    }*/
  @media screen and (max-width: 1430px) {
    /*display: none;*/
    position: fixed;
    top: 21px;
    right: 15px;//
  }

  & .lang_li {
    position: relative;
    display: block;
    text-align: center;
    color: $color-text;

    a {
      display: block;
      padding: 9px 0;
      color: $color-lang;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        color: $color-lang-hover;
      }
    }
  }

}

#lang-curr, #lang-curr-burger {
  font-size: 12px;
  color: $color-text;
  border: 1px solid $color-border;
  border-radius: 30px;
  height: 30px;
  padding: 5px 20px 0;

  @media screen and (max-width: 1430px) {
    border-color: transparent;
    line-height: 10px;
    padding: 0 10px;
  }

  @media screen and (max-width: 420px) {
    height: 28px;
  }

}

#lang-curr-burger {
  padding: 5px 0 0 0;
}

#lang-list,
#lang-list-burger {
  display: none;
  background-color: $color-text;
  position: absolute;
  z-index: 100;
  left: 15px;
  right: 15px;

  &:hover a:hover {
    background-color: $color-bg;
  }

  @media screen and (max-width: 420px) {
    left: 5px;
    right: 5px;
    top: 35px;
  }
}

.selected-lang {
  & .icon {
    color: $color-text;
  }
}

