* {
  font: 400 14px/1.4 'GothaProReg'; //
  color: #000;
  transition: font-size .4s ease;

}

:focus {
  outline: -webkit-focus-ring-color auto 0;
  outline: 0;
  box-shadow: 0;
}

.form-control:focus {
  background-color: #fff;
  border-color: inherit;
  outline: 0;
  box-shadow: none;
}

a {
  color: #000;

  &:hover {
    text-decoration: none;
  }
}

.container {
  @media (min-width: 576px) {
    max-width: 100%;
  }

  @media (min-width: 760px) {
    max-width: 100%;
  }
  @media (min-width: 1200px) {
    max-width: 1560px;
  }

  &_padding {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 0 30px;
    }
    @media screen and (min-width: 667px) and (max-width: 768px) {
      padding: 0 70px;
    }
    @media screen and (max-width: 667px) {

    }
  }

  &_inner {

    @media (min-width: 1200px) {
      max-width: 1100px;
    }
  }

  &_about {

    margin: 95px auto 0;
    padding-bottom: 95px;
    @media screen and (max-width: 1024px) {
      margin: 70px auto 0;
      padding-bottom: 70px;

    }
  }

}

/*

font-size: 18px;

  @media screen and (min-width: 768px ) and (max-width: 1024px ){
    font-size: 16px;
  }
  @media screen and (max-width: 768px ){
    font-size: 14px;
  }

*/

.title {
  &-page {
    text-align: center;
    margin: 95px auto 40px;
    @media screen and (max-width: 1024px) {
      margin: 70px auto 40px;
    }

    h2 {
      font: 700 34px 'GothaProBol';

      text-transform: uppercase;
      margin-bottom: 0;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    .description {
      width: 60%;
      margin: 40px auto 0;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 80%;
      }
      @media screen and (max-width: 768px) {
        width: 100%; //
      }

      p {
        font-size: 18px;
        text-align: left;
      }
    }

  }

}

.card {
  border: 0;

  &-header,
  &-body,
  &-footer {
    background-color: transparent;
    border: 0;
    padding: 0;
    color: white;
  }
}

.btn {
  &-place {
    margin-top: 75px;
    width: 215px;
    padding: 12px;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      text-decoration: none;
      color: #fff;
    }

  }

  &-tours-tour {
    color: white;
    margin-top: 20px;
    display: inline-block;

    width: 100%;
    border-radius: 4px;
    border: 2px solid #fff;
    padding: 12px;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
}

.show-more {
  margin: 10px auto 0;

  & > a {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #b30b0a;
    display: flex;
    align-items: center;

    & > .icon {
      font-size: 34px;
      color: #b30b0a;

    }

    &:hover {
      color: #544751;
      text-decoration: underline;

      .icon {
        color: #544751;
        text-decoration: none;

      }
    }

  }

}

.events,
.service {
  @extend %bg-color-darkgrey;
}

.background-fill-dark {
  @extend %bg-color-darkgrey;
}

.background-fill-light {
  @extend %bg-color-darkgrey;
  background-color: #ebebeb;
}

.congress-park {
  background-color: #ebebeb;
  margin: 0 0 70px;
  padding-bottom: 95px;
  @media screen and (max-width: 1024px) {
    margin: 0 0 50px;
    padding-bottom: 70px;
  }

}

.rest {

  padding-bottom: 95px;
  @media screen and (max-width: 1024px) {

    padding-bottom: 70px;
  }
  @media screen and (max-width: 569px) {
    .row {
      margin: 0;
    }

    .slide-rest {
      margin-left: -15px;
      margin-right: -15px;
    }

    .slick-dots li {
      margin: 0;
      width: 15px;
    }
  }

  @media screen and (max-width: 420px) {
    .slick-dots li {
      margin: 0 2.5px;
      width: 10px;
    }
  }

}

.events {
  @media screen and (max-width: 569px) {
    .slick-dots li {
      margin: 0;
      width: 15px;
    }
  }

  @media screen and (max-width: 420px) {
    .slick-dots li {
      margin: 0 2.5px;
      width: 10px;
    }
  }
}


.padding {
  padding-top: 95px;
  @media screen and (max-width: 1024px) {

    padding-top: 70px;
  }
}

.col_line {
  position: relative;

  // border-right: 2px solid #b5b5b5;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 2px;
    background-color: #b5b5b5;

  }

}

#modal-fullscreen {
  .modal-dialog {
    width: 100%;
    min-width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border: 0 none;
    border-radius: 0;
  }

  .modal-title {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5;
    margin-left: 15px;
    text-transform: uppercase;
  }

  .modal-header {
    padding: 15px 15px 0 15px;
    align-items: center; //
    .close {
      padding: 0;
      margin: 0;

      span {

        font-size: 30px;
        color: red;
      }
    }
  }

}

#topbar {
  display: none;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 64px;
  text-align: center;
  background: #928a7d;
  z-index: 999;

  @media screen and (min-width: 1024px) {
    opacity: 0;
    height: 0;
    background: transparent;
  }

  a {
    display: block;
    margin: 12px auto;
  }


}
