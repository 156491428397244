.icon,
.fa {
  font-family: $fontello-font-name;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;

  & span {
    font-family: "HelveticaNeueCyr", Helvetica, Arial, sans-serif;
  }
}

/*
<i class="fa fa-chevron-right"></i>
background-color: #cbbe96;
color: #000;
*/

.icon_lg {
  font-size: 4em;
}

.icon_md {
  font-size: 3em;
}

.icon_xsm {
  font-size: 2.5em;
}

.icon_sm {
  font-size: 2em;
}

.icon_xs {
  font-size: 1.3em;
  line-height: .75em;
}

.icon_blog {
  font-size: 0.9em;
  //width: 3em;

}

.icon_xxs {
  font-size: 1.0em;
}

/*
.icon-vkontakte{ @include fontello('\f189');}
*/




.icon-location-outline{ @include fontello('\e800');} /* '' */
.icon-search{ @include fontello('\e801');} /* '' */
.icon-phone{ @include fontello('\e802');} /* '' */
.icon-play{ @include fontello('\e803');} /* '' */
.icon-down-open{ @include fontello('\e804');} /* '' */
.icon-up-open{ @include fontello('\e805');} /* '' */
.icon-left-open{ @include fontello('\e806');} /* '' */
.icon-right-open{ @include fontello('\e807');} /* '' */
.icon-left-dir{ @include fontello('\e808');} /* '' */
.icon-up-dir{ @include fontello('\e809');} /* '' */
.icon-play-1{ @include fontello('\e80a');} /* '' */
.icon-down-dir-1{ @include fontello('\e80b');} /* '' */
.icon-right-dir-1{ @include fontello('\e80c');} /* '' */
.icon-down-open-mini{ @include fontello('\e80d');} /* '' */
.icon-user-1{ @include fontello('\e80e');} /* '' */
.icon-volume-up{ @include fontello('\e80f');} /* '' */
.icon-location{ @include fontello('\e810');} /* '' */
.icon-clipboard{ @include fontello('\e811');} /* '' */
.icon-rss-alt{ @include fontello('\e812');} /* '' */
.icon-search-1{ @include fontello('\e813');} /* '' */
.icon-align-left{ @include fontello('\e814');} /* '' */
.icon-left-open-mini{ @include fontello('\e815');} /* '' */
.icon-right-open-mini{ @include fontello('\e816');} /* '' */
.icon-up-open-mini{ @include fontello('\e817');} /* '' */
.icon-down-open-big{ @include fontello('\e818');} /* '' */
.icon-left-open-big{ @include fontello('\e819');} /* '' */
.icon-right-open-big{ @include fontello('\e81a');} /* '' */
.icon-up-open-big{ @include fontello('\e81b');} /* '' */
.icon-star{ @include fontello('\e81c');} /* '' */
.icon-mail{ @include fontello('\e81d');} /* '' */
.icon-mail-1{ @include fontello('\e81e');} /* '' */
.icon-mail-2{ @include fontello('\e81f');} /* '' */
.icon-clock{ @include fontello('\e820');} /* '' */
.icon-mail-3{ @include fontello('\e821');} /* '' */
.icon-clock-1{ @include fontello('\e822');} /* '' */
.icon-twitter-circled{ @include fontello('\e823');} /* '' */
.icon-calendar{ @include fontello('\e824');} /* '' */
.icon-twitter{ @include fontello('\f099');} /* '' */
.icon-facebook{ @include fontello('\f09a');} /* '' */
.icon-youtube-play{ @include fontello('\f16a');} /* '' */
.icon-instagram{ @include fontello('\f16d');} /* '' */
.icon-vkontakte{ @include fontello('\f189');} /* '' */
.icon-odnoklassniki{ @include fontello('\f263');} /* '' */
.icon-twitter-1{ @include fontello('\f309');} /* '' */
.icon-vkontakte-rect{ @include fontello('\f30a');} /* '' */
.icon-gplus{ @include fontello('\f30f');} /* '' */
.icon-gplus-circled{ @include fontello('\f310');} /* '' */
.icon-instagram-1{ @include fontello('\f32d');} /* '' */



.icon-list{ @include fontello('\e825');}
.icon-th{ @include fontello('\e826');}
.icon-list-1{ @include fontello('\e827');}




.berrosha-down-dir { @include fontello('\e800');} /* '' */
.berrosha-up-dir { @include fontello('\e801');} /* '' */
.berrosha-left-dir { @include fontello('\e802');} /* '' */
.berrosha-right-dir { @include fontello('\e803');} /* '' */
.berrosha-down-open { @include fontello('\e804');} /* '' */
.berrosha-left-open { @include fontello('\e805');} /* '' */
.berrosha-right-open { @include fontello('\e806');} /* '' */
.berrosha-up-open { @include fontello('\e807');} /* '' */
.berrosha-mail { @include fontello('\e808');} /* '' */
.berrosha-clock { @include fontello('\e809');} /* '' */
.berrosha-phone { @include fontello('\e80a'); transform: rotate(30deg);} /* '' */
.berrosha-location { @include fontello('\f031');} /* '' */
.berrosha-facebook { @include fontello('\f09a');} /* '' */
.berrosha-mail-alt { @include fontello('\f0e0');} /* '' */
.berrosha-angle-left { @include fontello('\f104');} /* '' */
.berrosha-angle-right { @include fontello('\f105');} /* '' */
.berrosha-angle-up { @include fontello('\f106');} /* '' */
.berrosha-angle-down { @include fontello('\f107');} /* '' */
.berrosha-tumblr { @include fontello('\f173');} /* '' */
.berrosha-vkontakte { @include fontello('\f189');} /* '' */
.berrosha-odnoklassniki { @include fontello('\f263');} /* '' */
.berrosha-instagram { @include fontello('\f32d');} /* '' */