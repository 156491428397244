.thumbnail-slider,
.thumbnail-slider-offer{

  &__bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/carousel/btn/3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px 4px 0 0;
    font-size: 0;
    margin: 0 15px;
    &:hover {
      .thumbnail-slider__img {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      }
      @media screen and (max-width: 1025px){
        margin: 0 15px 0 0;//
      }
    }
&_for {
  margin: 0;
  border-radius: 0;
}
  }


  &__text {
    height:450px;
    width: 40%;
    display: flex;
    align-items: flex-start;
    margin-left: 4%;
    flex-direction: column;
    justify-content: center;


    @media screen and (min-width: 667px) and (max-width: 1024px){
      width: 80%;
    }
    @media screen and (max-width: 667px){
      width: 90%;
    }


    h3 {
      height: 65px;
      font-size: 24px;
      line-height: 1.4;
      color: #fff;
    }
    p {
      font-size: 14px;
      line-height: 1.4;
      color: #fff;
    }

    .btn-place {
      margin-top: 45px;
    }

  }




  &__img {
    position: relative;
    display: inline-block;
    width: 100%;

    .info {
      position: absolute;
      left: 30px;
      right: 30px;
      top: 45px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      h3 {
        height: 65px;
        font-size: 18px;
        line-height: 1.4;
        color: #fff;
        align-items: center;
        text-align: left;
        text-transform: uppercase;
        margin: 0;

      }
      p {
        display: block;
        color: #fff;
        font-size: 14px;
        line-height: 1.3;
      }
    }


  }


  &-for {

    @extend %slick-dots;
  }

  &-nav {

    @media screen and (max-width: 768px){
display: none;
    }

    .slick-list{
      @media screen and (max-width: 1025px){
        padding:0 30px 0 0;
      }

      @media screen and (max-width: 769px){
        padding:0 80px 0 0;
      }

      @media screen and (max-width: 667px){
        padding:0 40px 0 0;
      }



    }



    @extend %slick-dots;
    @extend %slick-arrow;

    .slick-prev,
    .slick-next {
      position: absolute;
      z-index: 100;
      height: 101%;
      background-color: #fff;
      width: 4%;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        bottom: 0;
        width: 25px;
        height: 51px;
        margin-top: -25px;

      }
    }

    .slick-prev {
      left: 0;
      &:before {
        content: '';
        left: 0;
        background: url("/img/arrow-grey-left.png") center;

      }
    }

    .slick-next {
      right: 0;
      &:before {
        content: '';
        right: 0;
        background: url("/img/arrow-grey-right.png") center;

      }
    }

  }

}